import React, { useState } from "react";
import { APP_MASK } from "../../../configs/consts";
import { CtrItem, ExtrasItem } from "./styles";
import { foiPagoAvista, moeda, PagamentoAvista } from "../../../services/utils";
/* import { isAfter, parseISO, addDays } from "date-fns"; */
import FinancialItem from "../FinancialItem";

import GeraCobrancas from "../GeraCobrancas";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faFileSignature,
  faEye,
  faHandHoldingUsd,
  faBoxes,
  faBoxOpen,
  faLayerGroup,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

export default function ContractItem(props) {
  const { contrato, formatura, extras, parcelas, pacote } = props;
  const [showGboleto, setShowGboleto] = useState(false);
  const reajuste = formatura.FormReajuste >= 0 ? "reajuste" : "desconto";

  const totalContratoPago = contrato.AlParcelas.filter(
    (parc) =>
      (parc.parctipo === "Contrato" || parc.parctipo === "Extras") &&
      parc.parcvalorpago > 0
  ).reduce((a, v) => a + v.parcvalorpago, 0);
  const totalExtras = extras.reduce((a, v) => a + v.exvalor, 0);
  const totalPacote = pacote
    .filter((itt) => itt.pctItemAtivo === 1)
    .reduce((a, v) => a + v.pctItemValor * v.pctItemQuant, 0);

  const totalDevido =
    formatura.FormValorPrazo + totalExtras + totalPacote - totalContratoPago;

  /* const pagoavista = contrato.AlParcelas.filter(
    (parc) =>
      parc.valorpago === contrato.cttotal &&
      parc.parctipo === "Contrato" &&
      isAfter(parseISO(parc.parcpagoem), parseISO(formatura.FormLimiteAvista))
  ); */

  return (
    <CtrItem>
      <p className="contract-title">
        <FontAwesomeIcon
          className="icon-contract-title"
          icon={faFileSignature}
        />{" "}
        Contrato <strong>{contrato.ctid}</strong>{" "}
        {formatura.FormContrato && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${APP_MASK}/sysmeiden2.0/${formatura.FormContrato}`}
          >
            {" "}
            <FontAwesomeIcon
              title="Leia o pdf do seu contrato aqui na íntegra."
              icon={faEye}
            />
          </a>
        )}
      </p>
      <p className="contract-description">
        O valor total deste contrato é {moeda(formatura.FormValorPrazo)}.{" "}
        {formatura.FormReajuste !== 0 &&
          `Com um ${reajuste} de ${moeda(formatura.FormReajuste)}.`}
        <br />
        {totalExtras !== 0 && `Seus extras somam ${moeda(totalExtras)}.`}
        <br />
        {totalPacote !== 0 &&
          `Seus ítens do pacote somam ${moeda(totalPacote)}.`}
        <br />
        {!foiPagoAvista(parcelas) && (
          <PagamentoAvista
            limite={formatura.FormLimiteAvista}
            avista={formatura.FormValor}
          />
        )}
      </p>
      <div className="contract-footer">
        <p className="contract-total">
          {totalContratoPago
            ? `Total Pago: ${moeda(totalContratoPago)}`
            : `Você não tem pagamentos registrados.`}
        </p>
        {/* <p className="contract-total">Total em aberto: {moeda(totalDevido)}</p> */}
        {showGboleto && (
          <GeraCobrancas
            contrato={contrato.ctid}
            totais={{
              pago: totalContratoPago,
              extras: totalExtras,
              reajustes: formatura.FormReajuste,
              avista: formatura.FormValor,
              aprazo: formatura.FormValorPrazo,
              limite: formatura.FormLimiteAvista,
            }}
            via={1}
          ></GeraCobrancas>
        )}
        {!showGboleto && (
          <button
            className="icone-e-texto"
            title="Clique para escolher uma forma e um valor para gerar um pagamento."
            onClick={() => setShowGboleto(!showGboleto)}
          >
            <FontAwesomeIcon icon={faHandHoldingUsd} /> Fazer um pagamento agora
          </button>
        )}
      </div>
      {contrato.AlParcelas &&
        contrato.AlParcelas.filter(
          (parc) =>
            (parc.parctipo === "Contrato" || parc.parctipo === "Extras") &&
            parc.parcvisivel === 1
        ).map((parcela) => (
          <FinancialItem key={parcela.parcid} parcela={parcela} />
        ))}
      <br />
      {totalExtras !== 0 && (
        <>
          <p className="contract-title">
            <FontAwesomeIcon
              className="icon-contract-title"
              icon={faLayerGroup}
            />{" "}
            Ítens Extras
          </p>
          <p className="contract-description">
            Produtos e serviços extras adquiridos após a assinatura do contrato
          </p>
          {extras.map((it) => (
            <ExtrasItem key={it.excodigo}>
              <FontAwesomeIcon
                className="icon-extras"
                icon={faLayerGroup}
                title="Extras de pacote"
              />
              <span>{it.exdescricao}</span>
              <span>{moeda(it.exvalor)}</span>
            </ExtrasItem>
          ))}
        </>
      )}
      <br />
      {totalPacote !== 0 && (
        <>
          <p className="contract-title">
            <FontAwesomeIcon className="icon-contract-title" icon={faBoxes} />{" "}
            Ítens do Pacote
          </p>
          <p className="contract-description">
            Produtos e serviços que fazem parte do seu pacote
          </p>
          {pacote
            .filter((itt) => itt.pctItemAtivo === 1)
            .map((it) => (
              <ExtrasItem key={it.pctItemId} title={it.pctItemDesc}>
                <FontAwesomeIcon className="icon-extras" icon={faBoxOpen} />
                <span>{it.pctItemQuant}</span>
                {(it.pctItemReservado && (
                  <FontAwesomeIcon
                    className="icon-extras"
                    icon={faExclamationTriangle}
                    title="Este ítem depende de pagamento para confirmação."
                  />
                )) ||
                  null}
                <span>{it.pctItemNome}</span>
                <span title="Valor unitário">{`${moeda(
                  it.pctItemValor
                )}un`}</span>
              </ExtrasItem>
            ))}
        </>
      )}
    </CtrItem>
  );
}
