import React from "react";
import { ParcItem } from "./styles";
import {
  moeda,
  databoa,
  HistoricoPagamento,
  iconSelector,
} from "../../../services/utils";
import formas_api from "../../../services/formas_pagamento.json";
import GeraCobrancas from "../GeraCobrancas";

import { isAfter, parseISO } from "date-fns";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faClock,
  faCheck,
  faEyeSlash,
  faHandHoldingUsd,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import useClipboard from "react-use-clipboard";
import { notificar } from "../../../components/Notificador";

export default function FinancialItem(props) {
  const { parcela } = props;
  const [isCopied, setCopied] = useClipboard(parcela.parctransacao);
  if (isCopied) {
    notificar("Chave copiada. Cole no App do seu banco.", "verde");
    notificar(
      "ATENÇÃO: Só faça o pagamento se tiver certeza de não o ter feito antes. Nosso sistema pode levar até 72hs para confirmar seu PIX.",
      "amarelo",
      "embaixo",
      null,
      null,
      10000
    );
  }

  const handleCopyClipboard = (e) => {
    e.preventDefault();
    setCopied();
  };

  return (
    <ParcItem
      title={
        parcela.parcpagoem !== null
          ? `Pago em ${databoa(parcela.parcpagoem)} ${
              parcela.parcforma === 2 && parcela.parcorigem === "Portal"
                ? `(${parcela.parcdoc.split(" - ")[0]})`
                : ""
            }`
          : parcela.parcforma !== 6
          ? isAfter(new Date(), parseISO(parcela.parcvencimento))
            ? `Vencido desde ${databoa(
                parcela.parcvencimento
              )}. Ainda não foi confirmado o pagamento.`
            : `Vence em ${databoa(
                parcela.parcvencimento
              )}. Ainda não foi confirmado o pagamento.`
          : "Ainda não foi confirmado o pagamento."
      }
    >
      <span>
        {iconSelector(parcela.parcforma, formas_api)}{" "}
        {moeda(
          parcela.parcvalorpago !== 0
            ? parcela.parcvalorpago
            : parcela.parcvalor + parcela.parctaxa
        )}{" "}
        -{" "}
        <HistoricoPagamento
          vencimento={parcela.parcvencimento}
          criacao={parcela.parctimestamp}
          pagoem={parcela.parcpagoem}
          forma={parcela.parcforma}
        />
      </span>
      <span className="partial-state">
        {parcela.parcforma === 5 ? (
          isAfter(new Date(), parseISO(parcela.parcvencimento)) ? (
            <FontAwesomeIcon
              className="icon-partial pending"
              title="Este boleto já está vencido. Não é possível gerar segunda via."
              icon={faEyeSlash}
            />
          ) : (
            parcela.parcpagoem === null && (
              <GeraCobrancas
                contrato={parcela.parcconta}
                parcela={parcela}
                via={2}
                className="icon-partial pending"
              />
            )
          )
        ) : (
          ""
        )}

        {parcela.parcforma === 2 && parcela.parcpagoem === null ? (
          <a href={parcela.parctransacao} rel="noopener noreferrer">
            <FontAwesomeIcon
              className="icon-partial pending"
              title="Clique para continuar o processo de pagamento..."
              icon={faHandHoldingUsd}
            />
          </a>
        ) : (
          ""
        )}

        {parcela.parcforma === 6 && parcela.parcpagoem === null ? (
          <a onClick={(e) => handleCopyClipboard(e)}>
            <FontAwesomeIcon
              className="icon-partial pending"
              title="Clique para copiar o código de pagamento e continuar o processo..."
              icon={faHandHoldingUsd}
            />
          </a>
        ) : (
          ""
        )}

        {parcela.parcpagoem === null ? (
          <FontAwesomeIcon
            className="icon-partial pending"
            title="Aguardando pagamento..."
            icon={faClock}
          />
        ) : (
          <FontAwesomeIcon
            className="icon-partial ok"
            title="Parcela paga"
            icon={faCheck}
          />
        )}
      </span>
    </ParcItem>
  );
}
