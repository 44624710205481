import React, { Component } from "react";
import Modal from "react-modal";

import { StateContext } from "../../context/Context";
import {
  moeda,
  refreshFrete,
  resumeCreditos,
  focusMe,
  handleSaveCache,
  handleGetCache,
  handleClearCache,
} from "../../services/utils";
import Endereco from "../../components/Endereco";

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./styles.css";

//import more from '../../assets/icons/svg/flat/more.svg';
//import client from "../../assets/icons/svg/flat/008-database.svg";
import frete from "../../assets/icons/svg/flat/019-fast-delivery.svg";
import finalizar from "../../assets/icons/svg/flat/003-invoice.svg";
import trash from "../../assets/icons/svg/flat/garbage-2.svg";
import album from "../../assets/icons/svg/flat/home-2.svg";
import money from "../../assets/icons/svg/flat/040-money.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartArrowDown, faHistory } from "@fortawesome/free-solid-svg-icons";

import { notificar } from "../../components/Notificador";
import Creditos from "./Creditos";
import Resumo from "./Resumo";
import Frete from "./Frete";
import Tour from "reactour";
import t0 from "../../assets/tour-compl-0.png";
import t1 from "../../assets/tour-compl-1.png";
import t2 from "../../assets/tour-compl-2.png";
import t3 from "../../assets/tour-compl-3.png";
import t4 from "../../assets/tour-compl-4.png";
import params from "../../assets/icons/svg/flat/controls.svg";
import help from "../../assets/icons/svg/flat/046-customer-service.svg";
import { FLAG_CONVIDADO_ID } from "../../configs/consts";

const steps = [
  {
    selector: `[data-tour="tour-0"]`,
    content: () => (
      <div className="tour-content">
        <h4>Escolher fotos complementares</h4>
        <p>
          Primeiro você deve ir ao <strong>Gerenciador de Conteúdos</strong> na
          home do seu portal e escolher o álbum do qual você vai selecionar as
          fotos.
        </p>
        <img src={t0} alt="Ajuda" />
      </div>
    ),
  },
  {
    selector: `[data-tour="tour-1"]`,
    content: () => (
      <div className="tour-content">
        <h4>Acessar o menu</h4>
        <p>Aqui você acessa o menu de configurações para as fotos...</p>
        <img src={t1} alt="Acessar" />
      </div>
    ),
  },
  {
    selector: `[data-tour="tour-2"]`,
    content: () => (
      <div className="tour-content">
        <h4>Escolher o tipo</h4>
        <p>...e, seleciona o tipo de foto como complementar.</p>
        <img src={t2} alt="Selecionar" />
      </div>
    ),
  },
  {
    selector: `[data-tour="tour-3"]`,
    content: () => (
      <div className="tour-content">
        <h4>Tipo de complementar</h4>
        <p>
          Neste passo, você precisa marcar qual tipo de complementar você está
          escolhendo. Clique em produtos.
        </p>
        <img src={t3} alt="Tipo" />
      </div>
    ),
  },
  {
    selector: `[data-tour="tour-4"]`,
    content: () => (
      <div className="tour-content">
        <h4>Marcar produto</h4>
        <p>
          Nessa lista vão aparecer os produtos que você colocou no seu pedido. O
          número em vermelho indica a quantidade de fotos complementares que
          cada produto necessita. Escolha o produto ao qual esta foto deve
          complementar e está pronto.
        </p>
        <img src={t4} alt="Guardar" />
      </div>
    ),
  },
  // ...
];

class Cart extends Component {
  static contextType = StateContext; // chave de acesso ao contexto

  state = {
    checkout: [],
    isLoading: true,
    isTourOpen: false,
    cacheCart: [],
    viewModalFrete: false,
    temFrete: false,
    convidado: false
  };

  async componentDidMount() {
    if (this.context.state.cart.length === 0) {
      notificar("Você não tem itens no carrinho...");
      this.setState({
        isLoading: false,
      });
      //this.props.history.push("/");
    } else {
      /* if (this.context.state.cart.find((it) => it.tipo === "produto"))
        this.context.actions.setCheckout({ frete: [] }); */
      this.setState({
        temFrete: refreshFrete(this.context.state.cart),
        isLoading: false,
        convidado: Boolean(this.context.state.cadastro.AlCurso === FLAG_CONVIDADO_ID)
      });
      this.context.actions.setCheckout({
        frete: {},
        descontos: [],
        bruto: 0,
        liquido: 0,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const tFrete = refreshFrete(this.context.state.cart);
    if (prevState.temFrete !== tFrete) this.setState({ temFrete: tFrete });
  }

  closeTour = () => {
    this.setState({ isTourOpen: false });
  };

  handleHelp = () => {
    this.setState({ isTourOpen: true });
  };

  handleRecuperaCart = async () => {
    const cartCache = await handleGetCache("cart");
    if (cartCache) {
      localStorage.setItem("SPCart", JSON.stringify(cartCache.content));
      this.context.actions.setCart(cartCache.content);
      notificar("Seu último pedido aberto foi restaurado.");
    } else {
      notificar("Não há pedido aberto a restaurar.");
    }
  };

  handleCache = () => {
    const newCart = this.context.state.cart;
    newCart.push(this.state.cacheCart[0]);
    this.context.actions.setCart(newCart);
  };

  handleSemComplementar = (prod) => {
    const Confirmar = ({ onUndo, closeToast }) => {
      return (
        <div>
          <p>
            Será removida a obrigação de escolha de fotos complementares para
            este produto. Você concorda?{" "}
            <button
              className="bt-toast"
              onClick={onUndo}
              title="Clique aqui para remover a obrigação de escolher fotos complementares para esse produto."
            >
              Sim, concordo.
            </button>
            <button
              className="bt-toast"
              onClick={closeToast}
              title="Clique aqui para ignorar esta ação."
            >
              Não, quero escolher estas fotos.
            </button>
          </p>
        </div>
      );
    };

    notificar(
      ({ closeToast }) => (
        <Confirmar
          closeToast={closeToast}
          onUndo={() => this.removeComplementar(prod)}
        />
      ),
      "vermelho",
      "embaixo",
      null,
      null,
      15000
    );
  };

  removeComplementar = (prod) => {
    const newCart = this.context.state.cart.filter((p) => p !== prod);
    prod.complementar = 0;
    newCart.push(prod);
    this.context.actions.setCart(newCart);
  };

  removeComplCart = (prod, pai) => {
    const newCart = this.context.state.cart.filter((p) => p !== pai);
    const newCompls = pai.detalhes.complementares.filter((it) => it !== prod);
    pai.detalhes.complementares = newCompls;
    this.context.actions.setCart([pai, ...newCart]);
    notificar("Foto complementar excluída.", "vermelho", "embaixo");
  };

  removeItemCart = (prod) => {
    this.setState({
      cacheCart: this.context.state.cart.filter((p) => p === prod),
    });
    const newCart = this.context.state.cart.filter((p) => p !== prod);
    this.context.actions.setCart(newCart);
    if (!refreshFrete(this.context.state.cart))
      this.context.actions.setCheckout({ frete: [] });
    const Undo = ({ onUndo }) => {
      return (
        <div>
          <p>
            Ítem excluído.{" "}
            <button
              className="bt-toast"
              onClick={onUndo}
              title="Clique aqui para restaurar o ítem excluído."
            >
              Desfazer?
            </button>
          </p>
        </div>
      );
    };

    notificar(
      <Undo onUndo={() => this.handleCache()} />,
      "vermelho",
      "embaixo"
    );
  };

  handleClearCart = async () => {
    this.clearCart(); //limpa o carrinho e o checkout
  };

  handleFinalizar = (
    totalImpressas,
    totalDigitais,
    totalBruto,
    totalLiquido
  ) => {
    if (
      this.context.state.checkout.frete[0] === undefined &&
      this.state.temFrete
    ) {
      notificar("Você precisa escolher a forma de entrega primeiro.");
      focusMe("frete");
    } else if (
      this.context.state.cart
        .filter((it) => it.tipo === "produto")
        .reduce(
          (acc, itt) =>
            acc +
            (parseInt(itt.complementar) -
              parseInt(itt.detalhes.complementares.length)),
          0
        ) !== 0
    ) {
      notificar("Você não escolheu todas as fotos complementares necessárias.");
    } else {
      //notificar("Tudo certo.");
      this.context.actions.setCheckout({
        frete: totalLiquido > 0 ? this.context.state.checkout.frete : {},
        descontos: {
          impressas: totalImpressas,
          digitais: totalDigitais,
          total: totalDigitais.val + totalImpressas.val,
        },
        liquido: totalLiquido,
        bruto: totalBruto,
      });
      this.props.history.push("/cartpay");
    }
    return null;
  };

  render() {
    //console.log(FLAG_CONVIDADO_ID, this.context.state.cadastro.AlCurso, this.state.convidado);

    const modalStyle = {
      content: {
        maxWidth: "70vw",
        minWidth: "300px",
        margin: "auto",
        fontSize: "14px !important",
      },
    };

    //Cálculo geral de cobrança
    const {
      AlFormDados: { FormDownImpressas },
      AlCredFtDigital,
      AlCredFtImpressa,
    } = this.context.state.cadastro;

    const creditosDados = {
      FormDownImpressas,
      AlCredFtDigital,
      AlCredFtImpressa,
    };

    const { totalImpressas, totalDigitais } = resumeCreditos(
      creditosDados,
      this.context.state.cart
    );

    const comFrete =
      this.context.state.checkout.frete &&
        this.context.state.checkout.frete.length > 0 &&
        refreshFrete(this.context.state.cart)
        ? this.context.state.checkout.frete[0].valor
        : 0;

    const totalBruto =
      this.context.state.cart.reduce((a, v) => a + v.detalhes.valor * v.qt, 0) +
      comFrete;

    const totalLiquido = totalBruto - (totalDigitais.val + totalImpressas.val);

    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.viewModalFrete}
          onRequestClose={() => this.setState({ viewModalFrete: false })}
          style={modalStyle}
        >
          <Endereco
            view={Boolean(!this.context.state.cadastro.AlRevEndereco)}
            notificacao="Revise seus dados de endereço para podermos realizar a entrega corretamente."
          />
          {Boolean(this.context.state.cadastro.AlRevEndereco) && (
            <Frete
              aoClick={() => this.setState({ viewModalFrete: false })}
              tamanho={this.context.state.cart.reduce(
                (a, b) => Math.max(a, b.detalhes.tamanho),
                0
              )}
            />
          )}
        </Modal>

        <div className="container-cart">
          {this.context.state.cart.length > 0 && (
            <section id="cart-header">
              <div>
                <h2>
                  <FontAwesomeIcon icon={faCartArrowDown} /> Seu pedido:
                </h2>
                <span>
                  Confira as quantidades e medidas dos ítens selecionados e
                  finalize seu pedido.
                </span>
              </div>
            </section>
          )}
          {this.context.state.cart.length > 0 && (
            <section id="cart-checkout">
              <Resumo
                cart={this.context.state.cart}
                checkout={this.context.state.checkout}
              />
              {this.context.state.cart.length > 0 && !this.state.convidado && (
                <Creditos
                  dados={creditosDados}
                  cart={this.context.state.cart}
                />
              )}
              <button
                className="bt_checkout payment"
                type="button"
                title={
                  totalLiquido > 0
                    ? "Escolher forma de pagamento"
                    : "Finalizar pedido"
                }
                onClick={() =>
                  this.handleFinalizar(
                    totalImpressas,
                    totalDigitais,
                    totalBruto,
                    totalLiquido
                  )
                }
              >
                <img
                  src={totalLiquido > 0 ? money : finalizar}
                  className="icon32"
                  alt="Pagamento"
                />
                {totalLiquido > 0
                  ? `Pagar ${moeda(totalLiquido)}`
                  : `Finalizar`}
              </button>
              {this.state.temFrete && (
                <button
                  className="bt_checkout frete"
                  type="button"
                  title="Escolhe a modalidade de frete"
                  onClick={() => this.setState({ viewModalFrete: true })}
                >
                  <img src={frete} className="icon32" alt="Frete" />
                  {this.context.state.checkout.frete.length > 0 ? (
                    <span>{this.context.state.checkout.frete[0].nome}</span>
                  ) : (
                    <span> Forma de Entrega</span>
                  )}
                </button>
              )}
              <button
                className="bt_checkout volta"
                type="button"
                title="Retorna à lista de produtos"
                onClick={() => this.props.history.push("/")}
              >
                <img src={album} className="icon32" alt="Continue escolhendo" />
                <span> Voltar ao portal</span>
              </button>

              {/* <button
                className="bt_checkout clearCart"
                type="button"
                title="Limpar pedido"
                onClick={() => this.handleClearCart()}
              >
                <img src={minus} className="icon32" alt="Limpa o carrinho" />
                <span> Limpar Pedido</span>
              </button> */}
            </section>
          )}
          <section id="cart-list">
            <Loader
              id="load_list"
              visible={this.state.isLoading}
              type="ThreeDots"
              color="#0086c3"
            />

            {this.context.state.cart.length > 0 ? (
              this.context.state.cart.map((prod) => (
                <>
                  <article
                    key={prod.idCart}
                    title={prod.detalhes.descricao}
                    className="itemCart"
                  >
                    <img src={prod.caminho} alt="" />
                    <header>
                      <div className="prod-info">
                        <span>Foto - {prod.detalhes.nome}</span>
                        <span className="prod-marca">
                          ref-{prod.evento}-{prod.id}
                        </span>
                        <span>
                          {parseInt(prod.complementar) > 0 && (
                            <span>
                              {" "}
                              {prod.detalhes.complementares.length} de{" "}
                              {prod.complementar} foto
                              {parseInt(prod.complementar) > 1 ? "s" : ""}{" "}
                              complementar
                              {parseInt(prod.complementar) > 1 ? "es " : " "}
                              escolhidas.{" "}
                              <button
                                href=""
                                className="bt-link"
                                title="Saiba como adicionar as fotos complementares correspondentes."
                                onClick={() => this.handleHelp()}
                              >
                                Como selecionar?
                              </button>
                              <button
                                href=""
                                className="bt-link"
                                style={{ color: "darkred", marginLeft: "5px" }}
                                title="Comprar produto sem adquirir as fotos que o complementam."
                                onClick={() => this.handleSemComplementar(prod)}
                              >
                                Dispensar complementares?
                              </button>
                            </span>
                          )}
                        </span>
                      </div>
                      <div className="prod-discr">
                        <span className="prod-qt">
                          {prod.qt}{" "}
                          <span>
                            unidade{parseInt(prod.qt) > 1 && <span>s</span>}
                          </span>
                        </span>
                        <span className="prod-valor">
                          {"x " + moeda(prod.detalhes.valor)}
                        </span>
                      </div>

                      <img
                        src={trash}
                        className="icon32 bt-icon"
                        alt="Remover..."
                        title="Remove este ítem do pedido"
                        onClick={() => this.removeItemCart(prod)}
                      />
                    </header>
                  </article>
                  {prod.detalhes.complementares &&
                    prod.detalhes.complementares.length > 0 && (
                      <div className="itens_complementares">
                        {prod.detalhes.complementares.map((ft_compl) => (
                          <article
                            key={ft_compl.idCart}
                            title={ft_compl.detalhes.descricao}
                            className="itemCartProdCompl"
                          >
                            <img src={ft_compl.caminho} alt="" />
                            <header>
                              <div className="prod-info">
                                <span>Foto - {ft_compl.detalhes.nome}</span>
                                <span className="prod-marca">
                                  ref-{ft_compl.evento}-{ft_compl.id}
                                </span>
                                <span></span>
                              </div>
                              <div className="prod-discr">
                                <span className="prod-qt"></span>
                                <span className="prod-valor"></span>
                              </div>

                              <img
                                src={trash}
                                className="icon32 bt-icon"
                                alt="Remover..."
                                title="Remove esta foto complementar do produto"
                                onClick={() =>
                                  this.removeComplCart(ft_compl, prod)
                                }
                              />
                            </header>
                          </article>
                        ))}
                      </div>
                    )}
                </>
              ))
            ) : (
              <div className="cart-empty">
                <h2>Ops! Seu pedido não possui ítens.</h2>

                <button
                  className="bt_checkout volta"
                  type="button"
                  title="Retorna à lista de produtos"
                  onClick={() => this.props.history.push("/")}
                >
                  <img
                    src={album}
                    className="icon32"
                    alt="Continue escolhendo"
                  />
                  <span> Voltar ao portal</span>
                </button>
                <button
                  className="bt_checkout volta"
                  type="button"
                  title="Se você deixou um pedido em andamento em outro acesso ou computador tentaremos recuperar para você continuar ele aqui."
                  onClick={() => this.handleRecuperaCart()}
                >
                  <FontAwesomeIcon
                    icon={faHistory}
                    style={{ fontSize: "30px", color: "#999" }}
                  />

                  <span> Continuar um pedido anterior...</span>
                </button>
              </div>
            )}
          </section>
        </div>
        <Tour
          steps={steps}
          isOpen={this.state.isTourOpen}
          onRequestClose={this.closeTour}
          showNavigationNumber={false}
          showNumber={false}
          rounded={3}
          disableInteraction={true}
          showNavigation={false}
          showCloseButton={false}
          lastStepNextButton={
            <button
              className="icone-e-texto"
              onClick={() => this.props.history.push("/")}
            >
              Ok, entendi!
            </button>
          }
        />
      </React.Fragment>
    );
  }
}

export default Cart;
