import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { isAuthenticated } from "./services/auth";

import System from "./System";

import Home from "./pages/Home";
import Album from "./pages/Album";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Reset from "./pages/Reset";
import Forgot from "./pages/Forgot";
import Uploads from "./pages/Uploads";
import Cart from "./pages/Cart";

import Auto from "./pages/Autocadastro/Auto";
import Success from "./pages/Autocadastro/Success";
import Match from "./pages/Autocadastro/Match";
import NoHash from "./pages/Autocadastro/NoHash";
import Full from "./pages/Autocadastro/Full";
import Pay from "./pages/Cart/Pay";
import Produtos from "./pages/Produtos";
import Perfil from "./pages/Perfil";
import Policy from "./components/Policy";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/signin", state: { from: props.location } }}
        />
      )
    }
  />
);

const Routes = () => (
  <Switch>
    <PrivateRoute path="/" exact component={Home} />
    <PrivateRoute path="/resumo/:tipo/:retorno" component={Home} />
    <PrivateRoute path="/album/:id/:pageSize/:page" component={Album} />
    <PrivateRoute path="/produtos" component={Produtos} />
    <PrivateRoute path="/cart" component={Cart} />
    <PrivateRoute path="/perfil" component={Perfil} />
    <PrivateRoute path="/cartpay" component={Pay} />
    <Route path="/signup" component={SignUp} />
    <Route path="/policy" component={Policy} />
    <Route path="/signin/:auto?" component={SignIn} />
    <Route path="/reset/:tkn" component={Reset} />
    <Route path="/forgot" component={Forgot} />
    <PrivateRoute path="/uploads" component={Uploads} />

    <Route path="/autocadastro" component={Auto} />
    <Route path="/sign/:key" component={Auto} />
    <Route path="/sign/:key/:exec" component={Auto} />
    <Route path="/success" component={Success} />
    <Route path="/match" component={Match} />
    <Route path="/nohash" component={NoHash} />
    <Route path="/full" component={Full} />

    <Route path="/sistema" component={System} />

    <Route
      path="*"
      component={() => <h1>Ops! Não encontramos este caminho.</h1>}
    />
  </Switch>
);

export default Routes;
