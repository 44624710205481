import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    max-width: 95%;
    cursor: pointer;
  }
  label {
    padding: 5px;
    margin: 5px;
    input {
      padding: 5px;
      margin: 5px;
    }
  }
`;
