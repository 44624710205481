import React, { useContext, useState } from "react";
import { StateContext } from "../../context/Context";
import Policy from "../../components/Policy";

import { Container } from "./styles";
import { useHistory } from "react-router-dom";
import Endereco from "../../components/Endereco";
import {
  faFileContract,
  faMapMarked,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import DashboardItem from "../Home/DashboardItem";
import { DashboardList } from "../Home/styles";
import Cadastro from "../../components/Cadastro";

function Perfil(props) {
  const context = useContext(StateContext);
  const history = useHistory();

  return (
    <Container>
      <DashboardList>
        {/* <DashboardItem
          icon={faUser}
          caption="Dados Pessoais"
          description="Mantenha seus dados pessoais atualizados."
          loading={false}
        >
          <Cadastro view={true} perfil={true} />
        </DashboardItem> */}
        <DashboardItem
          icon={faMapMarked}
          caption="Dados de Localização"
          description="Mantenha seus dados de endereço atualizados."
          loading={false}
        >
          <Endereco view={true} perfil={true} />
        </DashboardItem>
        <DashboardItem
          icon={faFileContract}
          caption="Política de Privacidade e Termos de Adesão"
          description="Leia nossa política e os termos de adesão."
          loading={false}
        >
          <Policy bt={false} />
        </DashboardItem>
      </DashboardList>
    </Container>
  );
}

export default Perfil;
