import React from "react";

import "./styles/global.css";
import { Mensagem, Container } from "./styles/styles";

export default () => {
  return (
    <Container>
      <Mensagem>
        <h1>Então! Já temos um cadastro com seus dados.</h1>
        <p>
          Entre em contato com a sua produtora para saber se você já não havia
          sido cadastrado antes.
        </p>
        <a className="auto" href="https://estudio4producoes.com.br">
          Enquanto isso, clique aqui e visite nosso site...
        </a>
      </Mensagem>
    </Container>
  );
};
