import React from "react";

//estilos
import { Container } from "./styles";
import { Recado } from "../styled-components-global";
import { criaPix } from "../../services/utils";
import QRCode from "react-qr-code";
import useClipboard from "react-use-clipboard";
import { notificar } from "../Notificador";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import {
  CHAVE_PIX,
  TITULAR_PIX,
  CIDADE_PIX,
  EMAIL_FINANCEIRO,
  WHATSAPP_FINANCEIRO,
} from "../../configs/consts";

export default ({ dados, mensagem = null }) => {
  const code = criaPix({
    chave: CHAVE_PIX,
    mensagem: `${dados.parctipo} ${dados.parctipo === "E-commerce" || dados.parctipo === "Extras" ? dados.parcvenda : dados.parcconta
      }`,
    titular: TITULAR_PIX,
    cidade: CIDADE_PIX,
    transacao: `${dados.parcid}`,
    valor: dados.parcvalor,
  });
  const [isCopied, setCopied] = useClipboard(code);

  if (isCopied) {
    notificar("Chave copiada. Cole no App do seu banco.", "verde");
  }
  return (
    <Container>
      {mensagem && <Recado>{mensagem}</Recado>}
      <QRCode value={code} />
      <input type="text" value={code} />

      <button onClick={setCopied} className="icone-e-texto">
        <FontAwesomeIcon icon={faCopy} />
        Copiar
      </button>
      <p style={{ textAlign: "center" }}>
        Lembre-se de <strong>enviar o comprovante</strong> da transação para{" "}
        <a href={`mailto:${EMAIL_FINANCEIRO}`}>{EMAIL_FINANCEIRO}</a> ou pelo WhatsApp número <strong>{WHATSAPP_FINANCEIRO}</strong> para agilizar a liberação.
      </p>
    </Container>
  );
  //00020126650014br.gov.bcb.pix0114037240780001470225
  //Pagamento do pedido 14388520400005303986540529.30
  //5802BR5927SP Agência de Eventos Ltda.6015Porto Alegre-RS62210517999330-14388-47536304B79F
};
