import React from "react";
import { Container } from "./styles";

function Convites({ baixar, thumb }) {
  return (
    <Container>
      <img
        src={thumb}
        onClick={baixar}
        title="Clique para baixar o convite em alta resolução"
      />
      <p>
        Clique sobre ele para fazer o download.
      </p>
    </Container>
  );
}

export default Convites;
