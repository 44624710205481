import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import {
  MdCheckCircle,
  MdError,
  MdRemoveRedEye,
  MdMusicVideo,
  MdPlayArrow,
  MdAlarmOn,
} from "react-icons/md";
import { Container, FileInfo, Preview } from "./styles";

const FileList = ({
  files,
  onDelete,
  onLabelChange,
  onTimeChange,
  onObsChange,
  tipos,
  tipo,
}) => {
  const [tempo, setTempo] = useState(0);
  const [obsUpload, setObsUpload] = useState("");
  const [inicio, setInicio] = useState(0);
  useEffect(() => {
    if (document.getElementById("musica")) {
      const som = document.getElementById("musica");
      som.addEventListener("loadedmetadata", (event) => {
        setTempo(som.duration.toFixed(0));
      });
    }
  }, [files]);
  return (
    <Container>
      {files.map((uploadedFile) => (
        <li key={uploadedFile.id}>
          <FileInfo>
            {uploadedFile.mimetype &&
            uploadedFile.mimetype.includes("audio") ? (
              <MdMusicVideo
                style={{ marginRight: 8, marginLeft: -5 }}
                size={112}
                color="#999"
              />
            ) : (
              <Preview src={uploadedFile.preview} />
            )}
            <div>
              {uploadedFile.uploaded &&
                (uploadedFile.label ? (
                  <strong data-tipo={uploadedFile.tipo}>
                    {uploadedFile.label}
                  </strong>
                ) : uploadedFile.mimetype &&
                  uploadedFile.mimetype.includes("image") ? (
                  <select
                    onChange={(e) => {
                      /* console.log(
                        e.target.value,
                        uploadedFile.id,
                        tipo,
                        e.target.options.selectedIndex
                      ); */
                      onLabelChange(
                        e.target.value,
                        uploadedFile.id,
                        tipo,
                        e.target.options.selectedIndex
                      );
                    }}
                  >
                    <option
                      value="0"
                      title="Escolha o que esta imagem vai representar em sua colação."
                    >
                      Onde você quer usar esta imagem?
                    </option>
                    {tipos.map((tp) => (
                      <option
                        value={tp.nome}
                        id={tp.slug}
                        key={tp.id}
                        title={tp.desc}
                      >
                        {tp.nome}
                      </option>
                    ))}
                  </select>
                ) : (
                  <>
                    {tipo === "formatura" && (
                      <select
                        onChange={(e) =>
                          onLabelChange(e.target.value, uploadedFile.id)
                        }
                      >
                        <option
                          value="0"
                          title="Escolha o que esta música vai representar em sua colação."
                        >
                          Onde você quer usar esta música?
                        </option>
                        {tipos.map((tp) => (
                          <option value={tp.nome} key={tp.id} title={tp.desc}>
                            {tp.nome}
                          </option>
                        ))}
                      </select>
                    )}
                    {tipo !== "formatura" && (
                      <>
                        <span id="inicial">
                          Iniciar à partir{" "}
                          {
                            // eslint-disable-next-line
                            //inicio == 0 ? `do início` : `de ${inicio} seg.`
                            inicio == 0
                              ? `do início`
                              : `de ${new Date(inicio * 1000)
                                  .toISOString()
                                  .substr(14, 5)
                                  .replace(":", "m")}s.`
                          }
                        </span>
                        <div className="controleInicio">
                          <input
                            type="range"
                            min="0"
                            max={tempo}
                            onChange={(e) => setInicio(e.target.value)}
                            title="Confirme o ponto de início no ícone ao lado."
                          />
                          <MdAlarmOn
                            style={{
                              marginLeft: 8,
                              cursor: "pointer",
                            }}
                            title="Clique para confirmar o ponto de início da sua música."
                            size={20}
                            color="#999"
                            onClick={() =>
                              onTimeChange(inicio, uploadedFile.id)
                            }
                          />
                        </div>
                      </>
                    )}
                    <audio id="musica" preload="metadata">
                      <source
                        src={uploadedFile.url}
                        type={uploadedFile.mimetype}
                      />
                    </audio>
                  </>
                ))}
              <span>{uploadedFile.name}</span>

              {tipo === "formatura" &&
                ((uploadedFile.obs && <span>{uploadedFile.obs}</span>) || (
                  <span>
                    <textarea
                      onKeyUp={(e) => setObsUpload(e.target.value)}
                      title="Adicione aqui alguma informação pertinente a esta música."
                      placeholder="Observações:"
                    ></textarea>
                    <button
                      onClick={(e) => onObsChange(obsUpload, uploadedFile.id)}
                    >
                      Salvar...
                    </button>
                  </span>
                ))}
              <span>
                {uploadedFile.readableSize}{" "}
                <button
                  onClick={() =>
                    onDelete(uploadedFile.id, uploadedFile.mimetype)
                  }
                >
                  Excluir
                </button>
              </span>
            </div>
          </FileInfo>
          <div>
            {!uploadedFile.uploaded && !uploadedFile.error && (
              <CircularProgressbar
                styles={{
                  root: { width: 24 },
                  path: { stroke: "#7159c1" },
                }}
                strokeWidth={10}
                title="Aguarde o carregamento..."
                value={uploadedFile.progress}
              />
            )}

            {uploadedFile.url && (
              <a
                href={uploadedFile.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {uploadedFile.mimetype &&
                uploadedFile.mimetype.includes("audio") ? (
                  <MdPlayArrow
                    style={{ marginRight: 8 }}
                    title="Clique para ouvir sua música."
                    size={20}
                    color="#999"
                  />
                ) : (
                  <MdRemoveRedEye
                    style={{ marginRight: 8 }}
                    title="Clique para visualizar sua imagem."
                    size={20}
                    color="#999"
                  />
                )}
              </a>
            )}

            {uploadedFile.uploaded && (
              <MdCheckCircle
                size={24}
                title="Arquivo carregado com sucesso."
                color="#78e5d5"
              />
            )}
            {uploadedFile.error && (
              <MdError
                size={24}
                title="Este arquivo não pôde ser carregado corretamente."
                color="#e57878"
              />
            )}
          </div>
        </li>
      ))}
    </Container>
  );
};

export default FileList;
