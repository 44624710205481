import React from "react";
import { BASE_URL_BANK } from "../../configs/consts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faBarcode,
  faMapMarked,
} from "@fortawesome/free-solid-svg-icons";

import "./styles.css";
import { tiracento } from "../../services/utils";
import { useHistory } from "react-router-dom";

function FormBoleto(props) {
  const {
    dados,
    showBoleto,
    via,
    icone = faEye,
    texto = null,
    className,
    showRevEndereco,
  } = props;
  const history = useHistory();

  return (
    <form
      action={BASE_URL_BANK}
      method="post"
      className={via === 2 ? "via-2" : "via-1"}
    >
      <input type="text" name="idConv" defaultValue={dados.idConv} hidden />
      <input type="text" name="refTran" defaultValue={dados.refTran} hidden />
      <input type="text" name="valor" defaultValue={dados.valor} hidden />
      <input type="text" name="dtVenc" defaultValue={dados.dtVenc} hidden />
      <input
        type="text"
        name="tpPagamento"
        defaultValue={dados.tpPagamento}
        hidden
      />
      <input
        type="text"
        name="indicadorPessoa"
        defaultValue={dados.indicadorPessoa}
        hidden
      />
      <input
        type="text"
        name="tpDuplicata"
        defaultValue={dados.tpDuplicata}
        hidden
      />
      <input
        type="text"
        name="urlRetorno"
        defaultValue={dados.urlRetorno}
        hidden
      />
      <input type="text" name="cpfCnpj" defaultValue={dados.cpfCnpj} hidden />
      <input
        type="text"
        name="nome"
        defaultValue={tiracento(dados.nome)}
        hidden
      />
      <input
        type="text"
        name="endereco"
        defaultValue={tiracento(dados.endereco)}
        hidden
      />
      <input
        type="text"
        name="cidade"
        defaultValue={tiracento(dados.cidade)}
        hidden
      />
      <input type="text" name="uf" defaultValue={dados.uf} hidden />
      <input type="text" name="cep" defaultValue={dados.cep} hidden />
      <input type="text" name="msgLoja" defaultValue={dados.msgLoja} hidden />
      {showRevEndereco && (
        <button
          type="button"
          className="icone"
          title="Seus dados de endereço precisam ser atualizados."
          onClick={() => history.push("/perfil")}
        >
          <FontAwesomeIcon
            icon={faMapMarked}
            className="icon-partial pending"
          />
          {texto !== null && <span style={{ marginLeft: 5 }}> {texto}</span>}
        </button>
      )}
      {!showRevEndereco && showBoleto && via === 1 && (
        <button type="submit" className="icone-e-texto">
          <FontAwesomeIcon icon={faBarcode} />
          EXIBIR BOLETO
        </button>
      )}
      {!showRevEndereco && showBoleto && via === 2 && (
        <button
          type="submit"
          className="icone"
          title="Clique para ver uma segunda via deste boleto."
        >
          <FontAwesomeIcon icon={icone} className="icon-partial pending" />
          {texto !== null && <span style={{ marginLeft: 5 }}> {texto}</span>}
        </button>
      )}
    </form>
  );
}
export default FormBoleto;
