import React, { useState, useContext } from "react";
import useForm from "react-hook-form";
import { StateContext } from "../../context/Context";
import { format } from "date-fns";

//serviços
import cep from "cep-promise";
import api from "../../services/api";

//funções úteis
import { capitular } from "../../services/utils";

//estilos
import { Form, Container } from "./styles";
import { Recado } from "../styled-components-global";

export default ({ view, notificacao = null, perfil = false }) => {
  const context = useContext(StateContext);
  const [msg, setMsg] = useState([{ msg: null, type: null }]);
  const [show, setShow] = useState(view || false);
  const [blockSubmit, setBlockSubmit] = useState(false);
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async (data) => {
    setBlockSubmit(true);
    data.AlEndereco = capitular(data.AlEndereco);
    data.AlNumero = data.AlNumero.trim();
    data.AlComp = capitular(data.AlComp);
    data.AlBairro = capitular(data.AlBairro.trim());
    data.AlCEP = data.AlCEP.trim();
    data.AlCidade = capitular(data.AlCidade.trim());
    data.AlUF = data.AlUF.trim().toUpperCase();
    data.AlRevEndereco = format(new Date(), "yyyy-MM-dd' 'HH:mm:ss");

    await api.post("/revendereco", data).then((str) => {
      setMsg(str.data);
      api.get(`/perfil/cadastro`).then((dados) => {
        if (!dados.data.error) {
          context.actions.setCadastro(dados.data);
        }
      });
      if (!perfil) {
        setTimeout(() => {
          setShow(false);
        }, 2000);
      }
    });
  };

  function handleSeekCEP(dados) {
    cep(dados).then((dt) => {
      document.querySelector("#AlEndereco").value = dt.street;
      document.querySelector("#AlBairro").value = dt.neighborhood;
      document.querySelector("#AlCidade").value = dt.city;
      document.querySelector("#AlUF").value = dt.state;
    });
  }

  return (
    <Container>
      {show && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          {notificacao && (
            <Recado>
              <p>{notificacao}</p>
            </Recado>
          )}
          {errors.AlCEP && (
            <span className="errors-endereco">{errors.AlCEP.message}</span>
          )}
          <input
            type="text"
            id="AlCEP"
            name="AlCEP"
            placeholder="Preencha o CEP"
            defaultValue={context.state.cadastro.AlCEP}
            ref={register({
              required: "Você precisa preencher seu CEP.",
              maxLength: {
                value: 8,
                message: "O cep precisa ter 8 dígitos numéricos.",
              },
              pattern: {
                value: /[0-9]{8}$/,
                message: "Seu CEP não é válido.",
              },
            })}
            onBlur={(e) => handleSeekCEP(e.target.value)}
          />

          {errors.AlEndereco && (
            <span className="errors-endereco">{errors.AlEndereco.message}</span>
          )}
          <input
            type="text"
            id="AlEndereco"
            name="AlEndereco"
            placeholder="Endereço sem o número"
            defaultValue={context.state.cadastro.AlEndereco}
            ref={register({
              required: "Você precisa preencher seu endereço.",
            })}
          />

          {errors.AlNumero && (
            <span className="errors-endereco">{errors.AlNumero.message}</span>
          )}
          <input
            type="text"
            id="AlNumero"
            name="AlNumero"
            placeholder="Número"
            defaultValue={context.state.cadastro.AlNumero}
            /* onBlur={e => e.target.value = capitular(e.target.value)} */
            ref={register({
              required: "Você precisa preencher o número.",
            })}
          />

          {errors.AlComp && (
            <span className="errors-endereco">{errors.AlComp.message}</span>
          )}
          <input
            type="text"
            id="AlComp"
            name="AlComp"
            placeholder="Complemento ex: casa, ap, bloco..."
            defaultValue={context.state.cadastro.AlComp}
            ref={register({
              maxLength: {
                value: 14,
                message: "O complemento não pode ter mais de 14 caracteres.",
              },
            })}
          />

          {errors.AlBairro && (
            <span className="errors-endereco">{errors.AlBairro.message}</span>
          )}
          <input
            type="text"
            id="AlBairro"
            name="AlBairro"
            placeholder="Seu bairro"
            defaultValue={context.state.cadastro.AlBairro}
            /* onBlur={e => e.target.value = capitular(e.target.value)} */
            ref={register({
              required: "Você precisa preencher o bairro.",
            })}
          />

          {errors.AlCidade && (
            <span className="errors-endereco">{errors.AlCidade.message}</span>
          )}
          <input
            type="text"
            id="AlCidade"
            name="AlCidade"
            placeholder="Sua cidade"
            defaultValue={context.state.cadastro.AlCidade}
            /* onBlur={e => e.target.value = capitular(e.target.value)} */
            ref={register({
              required: "Você precisa preencher a cidade.",
            })}
          />

          {errors.AlUF && (
            <span className="errors-endereco">{errors.AlUF.message}</span>
          )}
          <input
            type="text"
            id="AlUF"
            name="AlUF"
            placeholder="UF"
            defaultValue={context.state.cadastro.AlUF}
            ref={register({
              required: "Você precisa preencher o estado.",
              maxLength: {
                value: 2,
                message: "O CEP precisa ter 2 caracteres apenas.",
              },
            })}
          />

          <button
            className="icone-e-texto"
            style={{ backgroundColor: "#0167ce", color: "#aba" }}
            type="submit"
            disabled={blockSubmit ? "disabled" : ""}
          >
            SALVAR ENDEREÇO
          </button>
          {msg.msg != null && (
            <Recado>
              <p>{msg.msg}</p>
            </Recado>
          )}
        </Form>
      )}
    </Container>
  );
};
