import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StateContext } from "./../../context/Context";

//dados e serviços
import { logout, dataUser } from "../../services/auth";

//estilos e icones
import { RecadoAdmin } from "../styled-components-global";
import { Head } from "../../pages/Home/styles";
import Logo from "../../assets/logo_e4_p.png";
//inativos temporariamente
import Badge, { Effect } from "react-notification-badge";
import {
  faUserAlt,
  faShoppingBasket,
  faHome,
  faPowerOff,
} from "@fortawesome/free-solid-svg-icons";

import CookieConsent from "react-cookie-consent";

export default function Header() {
  const { state } = useContext(StateContext);
  const history = useHistory();
  const dtUser = dataUser();
  const pag = window.location.href.split("/");

  function handleLogout() {
    logout();
    history.push("/");
  }

  return (
    /* dtUser.length && ( */
    (state.header && (
      <Head>
        {dtUser.master && (
          <RecadoAdmin>
            <p title="Todas as suas ações serão interpretadas pelo sistema como se fossem do cliente que você está personificando.">
              Você está em modo Admin.
            </p>
          </RecadoAdmin>
        )}
        <Link to="/" title="Voltar ao portal.">
          <img
            src={Logo}
            style={{ margin: 30 + "px" }}
            alt="Estúdio Formando"
          />
        </Link>
        <div className="perfil">
          <p>
            Olá, <strong>{state.user.name}</strong>. Este é o seu portal. <br />
            <span id="head-details">Seja bem-vindo.</span>
          </p>

          <Link to="/" className="icone-branco" title="Voltar ao Portal">
            <FontAwesomeIcon icon={faHome} />
          </Link>

          <Link
            to="/perfil"
            className="icone-branco"
            title="Veja e configure seus dados pessoais."
          >
            {/* <Badge count={state.cart.length} effect={Effect.SCALE} /> */}
            <FontAwesomeIcon icon={faUserAlt} />
          </Link>
          {state.cadastro && !state.cadastro.AlRestrito && (
            <Link
              to="/cart"
              className="icone-branco"
              title="Suas compras em andamento."
            >
              <Badge count={state.cart.length} effect={Effect.SCALE} />
              <FontAwesomeIcon icon={faShoppingBasket} />
            </Link>
          )}

          <button
            className="icone-branco"
            title="Sair do sistema."
            onClick={() => handleLogout()}
          >
            <FontAwesomeIcon icon={faPowerOff} />
          </button>
        </div>
        <CookieConsent buttonText="Entendido" cookieName="cookieConsentEF">
          Este site pode utilizar cookies para melhorar a experiência do
          usuário.
        </CookieConsent>
      </Head>
    )) ||
    null
  );
}
