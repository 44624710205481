import styled from "styled-components";

export const Container = styled.div`
  
  section#pay-list {
    grid-area: cart-list;
    margin: 15px auto;
    padding: 0 15px 0 15px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  article.item-pay-checkout {
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    background: #fff;
    border: 1px solid transparent;
    border-bottom: 1px solid #ddd;
    min-width: 100%;
    .icon-pay-checkout {
      width: 30%;
      font-size: 50px;
      color: #999;
      box-sizing: unset;
      align-items: left;
    }

    p {
      font-style: italic;
    }
    :hover {
      border: 1px solid #999;
      .icon-pay-checkout {
        color: #555;
      }
    }
  }

  .pay-total {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
    font-weight: 600;
    font-size: 1.2em;
  }

  .buttons {
    width: 270px;
    padding: 10px;
  }
  .bt_checkout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #eee;
    border: none;
    margin: 10px 0;
    height: 50px !important;
    padding: 10px;
    text-align: center;
    color: #666;
    font-size: 13px;
    cursor: pointer;
    width: 100%;
    img {
      margin-right: 10px;
    }
  }

  .bt_checkout:hover {
    background-color: #ddd;
  }
`;
