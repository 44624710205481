import React, { useState } from 'react';
import styled from 'styled-components';
import api from "../../../services/api";
import { databoa } from '../../../services/utils';

import AgendarHorarios from './AgendarHorarios';

const AgendaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

function Agenda() {

  // função onSubmit que será passada para o componente Agendamento
  const handleAgendamentoSubmit = async (data, horario, userId) => {
    /* const response = await api.post('/agendamentos', {data, horario, userId});
    if (response.ok) {
      const newAgendamento = { data, horario, userId };
      setAgendamentos([...agendamentos, newAgendamento]);
    } else {
      console.error('Erro ao criar novo agendamento');
    } */
    console.log(data, horario, userId);
    return 0;
  };

  return (
    <AgendaContainer>
      <h1>Agendamentos para {databoa('2023-04-01', true)}</h1>
      <AgendarHorarios
        onSubmit={handleAgendamentoSubmit}
      />
    </AgendaContainer>
  );
}

export default Agenda;
