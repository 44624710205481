import React, { Component } from "react";

//dados
import uuid from "react-uuid";
import api from "../../services/api";
import complementares_api from "../../services/complementares";
import { StateContext } from "./../../context/Context";
import { ALBUM_LIMIT, EXIBE_UP_COMPLEMENTAR, FLAG_CONVIDADO_ID } from "../../configs/consts";
import { isValid, addDays } from "date-fns";
import isFuture from "date-fns/isFuture";
import { databoa } from "../../services/utils";

//interface do usuario
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Recado } from "../../components/styled-components-global";
import { ProdList, ProdItem } from "./styles";
import { Pagina } from "../../components/Paginador/styles";
import { notificar } from "../../components/Notificador";
import Badge, { Effect } from "react-notification-badge";
import Tour from "reactour";
import t0 from "../../assets/tour-0.png";
import t1 from "../../assets/tour-1.png";
import t2 from "../../assets/tour-2.png";
import t3 from "../../assets/tour-3.png";

//icones
import down from "../../assets/icons/svg/flat/cloud-computing.svg";
import shop from "../../assets/icons/svg/flat/045-basket-white.svg";
import trash from "../../assets/icons/svg/flat/garbage-2-white.svg";
import check from "../../assets/icons/svg/flat/success-white.svg";
import goback from "../../assets/icons/svg/flat/goback-white.svg";
import Header from "../../components/Header/Header";
//inativos temporariamente
/* import more from "../../assets/icons/svg/flat/more.svg"; */
import params from "../../assets/icons/svg/flat/controls.svg";
import help from "../../assets/icons/svg/flat/046-customer-service.svg";

const steps = [
  {
    selector: `[data-tour="tour-ini"]`,
    content: () => (
      <div>
        <h3>Novo por aqui? Siga estas dicas.</h3>
        <div className="tour-init">
          <img src={help} alt="Ajuda" />
          <p>
            Este é um pequeno tutorial de como fazer seus pedidos. <br />
            Não precisa de ajuda agora? Clique fora desse aviso para ver depois.
          </p>
        </div>
      </div>
    ),
  },
  {
    selector: `[data-tour="tour-0"]`,
    content: () => (
      <div className="tour-content">
        <h4>Seletor de páginas</h4>
        <p>
          Este é o seletor de páginas deste álbum. O sistema carrega cerca de 50
          fotos em cada aba destas para que você tenha uma melhor experiência de
          navegação no portal.
        </p>
      </div>
    ),
  },
  {
    selector: `[data-tour="tour-1"]`,
    content: () => (
      <div className="tour-content">
        <h4>Fotos digitais livres</h4>
        <p>
          Clicar sobre esta nuvem{" "}
          <img style={{ width: 20, marginBottom: -5 }} alt="" src={down} />{" "}
          (caso seu contrato possua fotos digitais livres) irá baixar a foto
          escolhida em alta resolução.
        </p>
        <img src={t0} alt="Ajuda" />
      </div>
    ),
  },
  {
    selector: `[data-tour="tour-2"]`,
    content: () => (
      <div className="tour-content">
        <h4>Selecionar fotos para um pedido</h4>
        <p>
          Você deve clicar em configurações{" "}
          <img
            style={{ width: 20, marginBottom: -5, marginLeft: 5 }}
            src={params}
            alt="Ajuda"
          />{" "}
          para escolher suas fotos para impressão por medida. O número em
          vermelho (se houver), indica quantas unidades de bônus você tem
          daquela medida em seu pacote.
        </p>
        <img src={t1} alt="Ajuda" />
      </div>
    ),
  },
  {
    selector: `[data-tour="tour-3"]`,
    content: () => (
      <div className="tour-content">
        <h4>Ajustar a quantidade</h4>
        <p>
          Aqui você define quantas unidades desse ítem você quer em seu
          pedido...
        </p>
        <img src={t2} alt="Ajuda" />
      </div>
    ),
  },
  {
    selector: `[data-tour="tour-4"]`,
    content: () => (
      <div className="tour-content">
        <h4>Colocar no pedido</h4>
        <p>
          ...e, ao clicar na cesta, você coloca o ítem configurado no pedido.
        </p>
        <img src={t3} alt="Guardar" />
      </div>
    ),
  },
  // ...
];
class Album extends Component {
  static contextType = StateContext; // chave de acesso ao contexto

  state = {
    feed: [],
    feedLimit: ALBUM_LIMIT,
    feedInit: ALBUM_LIMIT,
    feedEnd: ALBUM_LIMIT,
    feedPage: 1,
    feedListPage: [],
    feedEvent: 0,
    medidas: [],
    variacoes: [],
    slug: null,
    qt: 1,
    isLoading: true,
    download_link: false,
    mask_view: false,
    view_mnu_medidas: false,
    view_mnu_quantidade: false,
    view_mnu_complementares: false,
    view_mnu_confirma_complementar: false,
    view_mnu_produto_complementar: false,
    mnu_confirma_complementar: [],
    view_mnu_download: false,
    imgPrv: "",
    prvShow: false,
    temLivres: false,
    ignorarCreditos: false,
    isTourOpen: localStorage.getItem("tour") ? false : true,
    limiteComplementares: null,
    view_item_complementar: false
  };

  async componentDidMount() {
    document.addEventListener("contextmenu", (event) => event.preventDefault()); //bloqueio do botão direito
    document.addEventListener("keydown", function (e) {
      var keyCode = e.keyCode ? e.keyCode : e.which;
      if (keyCode == 44) {
        copyToClipboard();
      }
    });
    function copyToClipboard() {
      var aux = document.createElement("input");
      aux.setAttribute("value", "print screen disabled!");
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      // Remove it from the body
      document.body.removeChild(aux);
      alert("A cópia destas imagens viola os direitos autorais.");
    }

    if (this.context.state.cadastro.length === 0) {
      this.setState({
        cadastro: JSON.parse(sessionStorage.getItem("SPCad")),
      });
    } else {
      this.setState({ cadastro: this.context.state.cadastro });
    }
    await api.get(`perfil/valores-fotos`).then((response) => {
      this.setState({
        variacoes: response.data,
      });
    });
    const { id, pageSize, page } = this.props.match.params;

    await api
      .get(`eventos/album/${id}`)
      .then((response) => {
        const qt = Math.ceil(response.data.length / pageSize);
        const pg = [];
        for (let i = 1; i <= qt; i++) {
          pg.push(i);
        }

        this.setState({
          feed: response.data,
          feedPage: page,
          feedListPage: pg,
          feedLimit: pageSize,
          feedEnd: page * pageSize,
          feedInit: page * pageSize - pageSize,
          feedEvent: id,
          isLoading: false,
          isLoading_item: true,
          ignorarCreditos: response.data[0].ignorarCreditos || Boolean(this.context.state.cadastro.AlCurso === FLAG_CONVIDADO_ID),
          temLivres: Boolean(this.state.cadastro.AlFormDados.FormTodas),
          limiteComplementares:
            this.state.cadastro.AlFormDados.FormLimiteComplementares,
          view_item_complementar:
            isValid(
              new Date(this.state.cadastro.AlFormDados.FormLimiteComplementares)
            ) &&
            isFuture(
              addDays(
                new Date(
                  this.state.cadastro.AlFormDados.FormLimiteComplementares
                ),
                1
              )
            ) &&
            !Boolean(EXIBE_UP_COMPLEMENTAR),
        });
        if (this.state.view_item_complementar)
          notificar(
            `Não esqueça de escolher suas fotos complementares. você tem até ${databoa(
              this.state.limiteComplementares
            )}.`,
            "amarelo",
            "embaixo",
            null,
            null,
            7000
          );
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.responderEnd);
        }
      });
  }

  async componentDidUpdate(prevProps) {
    /* if (this.context.state.cart) {
      localStorage.setItem("SPCart", JSON.stringify(this.context.state.cart));
    } */
    const { page } = this.props.match.params;
    if (page !== prevProps.match.params.page) {
      const { id, pageSize, page } = this.props.match.params;

      await api
        .get(`eventos/album/${id}`)
        .then((response) => {
          const qt = Math.ceil(response.data.length / pageSize);
          const pg = [];
          for (let i = 1; i <= qt; i++) {
            pg.push(i);
          }

          this.setState({
            feed: response.data,
            feedPage: page,
            feedListPage: pg,
            feedLimit: pageSize,
            feedEnd: page * pageSize,
            feedInit: page * pageSize - pageSize,
            feedEvent: id,
            isLoading: false,
            isLoading_item: true,
            ignorarCreditos: response.data[0].ignorarCreditos || Boolean(this.context.state.cadastro.AlCurso === FLAG_CONVIDADO_ID),
            temLivres: Boolean(this.state.cadastro.AlFormDados.FormTodas),
            limiteComplementares:
              this.state.cadastro.AlFormDados.FormLimiteComplementares,
            view_item_complementar:
              isValid(
                new Date(
                  this.state.cadastro.AlFormDados.FormLimiteComplementares
                )
              ) &&
              isFuture(
                addDays(
                  new Date(
                    this.state.cadastro.AlFormDados.FormLimiteComplementares
                  ),
                  1
                )
              ),
          });
          if (this.state.view_item_complementar)
            notificar(
              `Não esqueça de escolher suas fotos complementares. você tem até ${databoa(
                this.state.limiteComplementares
              )}.`,
              "amarelo",
              "embaixo",
              null,
              null,
              7000
            );
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.responderEnd);
          }
        });
    }
  }

  closeTour = () => {
    this.setState({ isTourOpen: false });
  };

  handleMedidas(id) {
    let mdfilter =
      this.state.temLivres && !this.state.ignorarCreditos
        ? this.state.variacoes.filter((it) => it.slug !== "digital")
        : this.state.variacoes;

    this.setState({
      mask_view: id,
      medidas: mdfilter,
      view_mnu_medidas: true,
      view_mnu_quantidade: false,
      view_mnu_complementares: false,
      view_mnu_confirma_complementar: false,
      view_mnu_produto_complementar: false,
      isLoading_item: false,
    });
  }

  /* let medfilter = [];

      if (this.state.view_item_complementar === false ) {
        medfilter = mdfilter.filter((it) => it.slug !== "complementar");
      } else {
        medfilter = mdfilter;
      }) */

  handleMedClick = async (slug) => {
    const compl =
      slug === "complementar" && this.state.view_item_complementar === true;
    let complementares = [];
    if (compl) {
      const ret = await api.get("/complementar");
      if (ret.data.length) {
        complementares = complementares_api.filter((it) =>
          ret.data.map((its) => {
            if (it.slug === its.tipo) {
              it.caminho = its.content[0].caminho;
            }
            return true;
          })
        );
      } else {
        complementares = complementares_api;
      }
    } else {
      complementares = complementares_api.filter((it) => it.slug === "produto");
    }
    this.setState({
      slug,
      compl: complementares,
      view_mnu_medidas: false,
      view_mnu_quantidade: slug === "complementar" ? false : true,
      view_mnu_complementares: slug === "complementar" ? true : false,
      view_mnu_confirma_complementar: false,
      view_mnu_produto_complementar: false,
    });
  };

  handleComplClick = (slug) => {
    const dadosCompl = this.state.compl.filter((it) => it.slug === slug);
    this.setState({
      view_mnu_medidas: false,
      view_mnu_quantidade: false,
      view_mnu_complementares: false,
      mnu_confirma_complementar: dadosCompl,
      view_mnu_confirma_complementar: slug === "produto" ? false : true,
      view_mnu_produto_complementar: slug === "produto" ? true : false,
    });
  };

  handleAddCompl = async (prod, tp) => {
    await api.post("/complementar", { tipo: tp, content: prod });

    notificar("Foto complementar selecionada.", "verde", "embaixo");
    this.setState({
      view_mnu_medidas: false,
      view_mnu_quantidade: false,
      view_mnu_complementares: false,
      view_mnu_produto_complementar: false,
      mnu_confirma_complementar: [],
      view_mnu_confirma_complementar: false,
    });
  };

  backAddCart = () => {
    this.setState({
      view_mnu_medidas: true,
      view_mnu_quantidade: false,
    });
  };

  handleAddCartCompl = (pd, prd_id) => {
    const prodCart = { ...pd };
    prodCart.idCart = uuid();
    prodCart.slug = this.state.slug;
    prodCart.tipo = "complementar";
    prodCart.qt = 1;
    const detalhes = this.state.medidas.filter(
      (it) => prodCart.slug === it.slug
    );
    prodCart.detalhes = detalhes[0];

    let pai = this.context.state.cart.find((p) => p.idCart === prd_id);
    let busca = pai.detalhes.complementares.filter(
      (p) => p.slug === prodCart.slug && p.id === prodCart.id
    );
    //verifica se já não está no carrinho
    if (busca.length > 0) {
      notificar("Esta foto já complementa este produto.", "amarelo", "embaixo");
    } else {
      const newCart = this.context.state.cart.filter(
        (it) => it.idCart !== pai.idCart
      );
      pai.detalhes.complementares.push(prodCart);
      newCart.push(pai);
      this.context.actions.setCart(newCart);
      this.setState({
        view_mnu_medidas: false,
        view_mnu_quantidade: false,
        view_mnu_complementares: false,
        view_mnu_produto_complementar: false,
        mnu_confirma_complementar: [],
        view_mnu_confirma_complementar: false,
        qt: 1,
        slug: null,
      });

      notificar(
        ({ closeToast }) => (
          <p>
            Foto complementar de produto adicionada.{" "}
            <button
              className="bt-toast"
              onClick={() => this.props.history.push("/cart")}
            >
              Ver carrinho?
            </button>
          </p>
        ),

        "verde",
        "embaixo"
      );
    }
  };

  handleAddCart = (prod) => {
    const prodCart = { ...prod };
    prodCart.idCart = uuid();
    prodCart.slug = this.state.slug;
    prodCart.tipo = "foto";
    prodCart.qt = this.state.slug === "digital" ? 1 : parseInt(this.state.qt);
    const detalhes = this.state.medidas.filter(
      (it) => prodCart.slug === it.slug
    );
    prodCart.detalhes = detalhes[0];
    let busca = this.context.state.cart.filter(
      (p) =>
        p.slug === prodCart.slug &&
        p.evento === prodCart.evento &&
        p.id === prodCart.id
    );
    //verifica se já não está no carrinho
    if (busca.length > 0) {
      notificar(
        "Este produto nesta medida já está no seu pedido.",
        "amarelo",
        "embaixo"
      );
      this.backAddCart();
    } else {
      this.context.actions.setCart([prodCart, ...this.context.state.cart]);

      this.setState({
        view_mnu_quantidade: false,
        qt: 1,
        slug: null,
      });
      notificar(
        ({ closeToast }) => (
          <p>
            Ítem adicionado.{" "}
            <button
              className="bt-toast"
              onClick={() => this.props.history.push("/cart")}
            >
              Ver carrinho?
            </button>
          </p>
        ),
        /* `${prodCart.id} - ${prodCart.detalhes.nome} adicionado ao carrinho`, */
        "verde",
        "embaixo"
      );
    }
  };

  cancelAddCart = () => {
    this.setState({
      view_mnu_quantidade: false,
      view_mnu_medidas: false,
      view_mnu_complementares: false,
      view_mnu_confirma_complementar: false,
      view_mnu_produto_complementar: false,
      mnu_confirma_complementar: [],
      mask_view: false,
      qt: 1,
      slug: null,
    });
  };

  handleChange = (event) => {
    this.setState({
      qt: event.target.value,
    });
  };

  handleEscape = (event) => {
    event.stopPropagation();
    if (event.key === "Escape") {
      this.setState({
        view_mnu_quantidade: false,
        mask_view: false,
        qt: 1,
        slug: null,
      });
    }
  };

  handlePreview = (props) => {
    this.setState({
      prvShow: !this.state.prvShow,
    });
    return <img src={props.img} alt="Preview de foto" />;
  };

  handleHelp = () => {
    this.setState({ isTourOpen: true });
  };

  handleClickPage = (pg) => {
    this.props.history.push(
      `/album/${this.state.feedEvent}/${this.state.feedLimit}/${pg}`
    );
    this.setState({ feedPage: pg });
  };

  handleDown = async (id, caminho) => {
    const registro = {
      id,
      evento: this.state.feedEvent,
      caminho,
    };

    api
      .post("/singledown", registro, { responseType: "arraybuffer" })
      .then((response) => new Blob([response.data]))
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "img.jpg");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        notificar("Sua foto está sendo baixada.", "verde", "embaixo");
      })
      .catch((err) => {
        notificar("Não foi possível baixar esta foto.", "vermelho", "embaixo");
      });
  };

  countCred = (tipo) => {
    const credImpressa = this.state.cadastro.AlCredFtImpressa;
    const credDigital = this.state.cadastro.AlCredFtDigital;
    const imp_dig = this.state.cadastro.AlFormDados.FormDownImpressas;

    if (credDigital > 0 || credImpressa > 0) {
      let ret = 0;
      switch (tipo) {
        case "digital":
          ret = credDigital;
          break;
        case "dig+imp":
          if (imp_dig === 1) {
            ret = credImpressa;
          } else {
            return 0;
          }
          break;
        case "15x21":
          if (imp_dig === 0) {
            ret = credImpressa;
          } else {
            return 0;
          }
          break;
        default:
          return 0;
      }
      const rt = this.context.state.cart.filter((it) => it.slug === tipo);
      return this.state.ignorarCreditos
        ? 0
        : ret - rt.reduce((a, v) => a + parseInt(v.qt), 0);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-feed">
          <Recado>
            <p>Passe o mouse sobre as imagens para ver as opções. </p>
            <button
              style={{
                width: 42,
                height: 42,
                padding: 2,
                marginLeft: 10,
                borderRadius: 100,
              }}
              className="icone-e-texto"
              title="Precisa de ajuda?"
              onClick={() => this.handleHelp()}
            >
              <img style={{ width: 26 }} alt="" src={help} />
            </button>
          </Recado>
          <Pagina data-tour="tour-0">
            {this.state.feedListPage.map((it) => (
              <li
                className={
                  it === parseInt(this.state.feedPage) ? "active" : "item"
                }
                key={it}
                onClick={() => this.handleClickPage(it)}
              >
                {it}
              </li>
            ))}
          </Pagina>
          <ProdList id="prod-list" data-tour="step-2">
            <Loader
              id="load_list"
              visible={this.state.isLoading}
              type="ThreeDots"
              color="#0086c3"
            />

            {this.state.feed
              .slice(this.state.feedInit, this.state.feedEnd)
              .map((prod) => (
                <ProdItem key={prod.id}>
                  {this.state.mask_view === prod.id && (
                    <div className="mask" id={prod.id}>
                      <Loader
                        id="load_item"
                        visible={this.state.isLoading_item}
                        type="ThreeDots"
                        color="#fff"
                      />

                      {this.state.medidas.length > 0 &&
                        this.state.view_mnu_medidas && (
                          <ul id="mnuBit" onClick={(e) => this.handleEscape(e)}>
                            <h3>Escolha a medida: </h3>
                            {this.state.medidas.map((bit) => (
                              <li className="bit_item" key={bit.slug}>
                                <button
                                  onClick={() => this.handleMedClick(bit.slug)}
                                  title={
                                    this.countCred(bit.slug) > 0
                                      ? "Você possui créditos no seu pacote para esta medida. Aproveite!"
                                      : undefined
                                  }
                                >
                                  {(["dig+imp", "15x21", "digital"].includes(
                                    bit.slug
                                  ) && (
                                      <Badge
                                        count={this.countCred(bit.slug)}
                                        effect={Effect.SCALE}
                                        style={{ margin: "0 -25px" }}
                                      />
                                    )) ||
                                    null}
                                  {bit.nome}
                                </button>
                              </li>
                            ))}
                          </ul>
                        )}

                      {this.state.view_mnu_complementares && (
                        <ul id="mnuCompl" onClick={(e) => this.handleEscape(e)}>
                          <h3>Defina sua escolha: </h3>
                          {this.state.compl.map((bit) => (
                            <li className="bit_item" key={bit.slug}>
                              <button
                                onClick={() => this.handleComplClick(bit.slug)}
                                title={bit.tipo}
                              >
                                {bit.nome}{" "}
                                {bit.hasOwnProperty("caminho") && (
                                  <img
                                    src={check}
                                    alt="Confirma"
                                    style={{
                                      width: "15px",
                                      marginBottom: "-4px",
                                      marginLeft: "10px",
                                    }}
                                    title="Foto já selecionada."
                                  />
                                )}
                              </button>
                            </li>
                          ))}
                        </ul>
                      )}
                      {this.state.view_mnu_produto_complementar && (
                        <div
                          id="mnuProdCompl"
                          onClick={(e) => this.handleEscape(e)}
                        >
                          <h3>
                            {this.state.mnu_confirma_complementar[0].nome}:
                          </h3>
                          <p>
                            {this.state.mnu_confirma_complementar[0].descricao}
                          </p>
                          {(this.context.state.cart.filter(
                            (p) => p.tipo === "produto" && p.complementar > 0
                          ).length > 0 && (
                              <p>
                                <p>
                                  Os números em vermelho correspondem às fotos que
                                  faltam para compor o produto.
                                </p>
                                <strong>
                                  Escolha o produto ao qual esta foto deve
                                  pertencer:
                                </strong>
                              </p>
                            )) || (
                              <p>
                                <strong>
                                  Para usar este recurso você precisa ter produtos
                                  que necessitem de fotos complementares em seu
                                  pedido.
                                </strong>
                              </p>
                            )}
                          <ul style={{ width: "100%" }}>
                            {this.context.state.cart.length > 0 &&
                              this.context.state.cart
                                .filter(
                                  (p) =>
                                    p.tipo === "produto" && p.complementar > 0
                                )
                                .map(
                                  (prd) =>
                                    prd.complementar >
                                    prd.detalhes.complementares.length && (
                                      <li className="bit_item" key={prd.idCart}>
                                        <button
                                          title={prd.detalhes.descricao}
                                          onClick={() =>
                                            this.handleAddCartCompl(
                                              prod,
                                              prd.idCart
                                            )
                                          }
                                        >
                                          <Badge
                                            count={
                                              prd.complementar -
                                              prd.detalhes.complementares.length
                                            }
                                            effect={Effect.SCALE}
                                            style={{ margin: "0 -25px" }}
                                            title="Esta é a quantidade de fotos que faltam para compor este produto."
                                          />
                                          {prd.nome}
                                        </button>
                                      </li>
                                    )
                                )}
                          </ul>

                          <button
                            className="bt_alpha"
                            type="button"
                            onClick={() => this.cancelAddCart()}
                          >
                            <img
                              src={trash}
                              className="icon32"
                              alt="Cancela"
                              title="NÃO escolher esta foto!"
                            />
                          </button>
                          <p
                            style={{
                              fontSize: "0.8rem",
                            }}
                          >
                            Obs.: Para excluir uma foto complementar vá ao
                            carrinho.
                          </p>
                        </div>
                      )}

                      {this.state.view_item_complementar &&
                        this.state.view_mnu_confirma_complementar && (
                          <div
                            id="mnuConfCompl"
                            onClick={(e) => this.handleEscape(e)}
                          >
                            <h3>
                              {this.state.mnu_confirma_complementar[0].nome}:
                            </h3>
                            <p>
                              {
                                this.state.mnu_confirma_complementar[0]
                                  .descricao
                              }
                            </p>
                            <p>
                              {this.state.mnu_confirma_complementar[0].hasOwnProperty(
                                "caminho"
                              ) ? (
                                <>
                                  <img
                                    src={
                                      this.state.mnu_confirma_complementar[0]
                                        .caminho
                                    }
                                    style={{ maxWidth: "280px" }}
                                  />
                                  <strong>
                                    Você escolheu esta outra foto recentemente.
                                    Caso queira mantê-la, REJEITE sua escolha
                                    atual abaixo.
                                  </strong>
                                </>
                              ) : (
                                <strong>
                                  Confira se a foto escolhida atende todos os
                                  critérios acima e CONFIRME sua escolha abaixo.
                                </strong>
                              )}
                            </p>
                            <button
                              className="bt_alpha"
                              type="button"
                              onClick={() =>
                                this.handleAddCompl(
                                  prod,
                                  this.state.mnu_confirma_complementar[0].slug
                                )
                              }
                            >
                              <img
                                src={check}
                                alt="Confirma"
                                className="icon32"
                                title="Quero esta foto!"
                              />
                            </button>

                            <button
                              className="bt_alpha"
                              type="button"
                              onClick={() => this.cancelAddCart()}
                            >
                              <img
                                src={trash}
                                className="icon32"
                                alt="Cancela"
                                title="NÃO escolher esta foto!"
                              />
                            </button>
                          </div>
                        )}

                      {this.state.view_mnu_quantidade && (
                        <div id="mnuQt" onClick={(e) => this.handleEscape(e)}>
                          <h3>Escolha a quantidade: </h3>
                          <input
                            type="number"
                            min="1"
                            className="qt_item bt_alpha"
                            onChange={this.handleChange}
                            value={this.state.qt}
                            placeholder="Digite um número"
                          />

                          <button
                            className="bt_alpha addCart"
                            type="button"
                            onClick={() => this.handleAddCart(prod)}
                          >
                            <img
                              src={shop}
                              alt="Pedido"
                              className="icon32"
                              title="Colocar no pedido"
                            />
                          </button>

                          <button
                            className="bt_alpha addCancel"
                            type="button"
                            onClick={() => this.cancelAddCart()}
                          >
                            <img
                              src={trash}
                              className="icon32"
                              alt="Cancelar Item"
                              title="Não colocar no pedido"
                            />
                          </button>

                          <button
                            className="bt_alpha addBack"
                            type="button"
                            onClick={() => this.backAddCart()}
                          >
                            <img
                              src={goback}
                              className="icon32"
                              alt="Voltar às medidas"
                              title="Voltar às medidas"
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  )}

                  <header>
                    {/* <button
                      type="button"
                      onClick={() => this.handleMedidas(prod.id)}
                    >
                      <img src={more} className="icon24" alt="Mais..." />
                    </button> */}
                    {this.state.temLivres && !prod.ignorarCreditos && (
                      <button
                        type="button"
                        onClick={() => this.handleDown(prod.id, prod.caminho)}
                        title="Baixar esta foto em alta resolução agora."
                      >
                        <img src={down} className="icon24" alt="Baixar foto" />
                      </button>
                    )}
                    <span>{prod.id}</span>
                    <button
                      type="button"
                      onClick={() => this.handleMedidas(prod.id)}
                      title="Configurar para colocar no pedido."
                    >
                      <img
                        src={params}
                        className="icon32"
                        alt="Medidas"
                        title="Configurar para colocar no pedido."
                      />
                    </button>
                  </header>
                  <img
                    className="img-album"
                    src={prod.caminho}
                    alt="Fotos do Album"
                    onClick={() => this.handlePreview(prod.caminho)}
                  />
                  <footer>{ }</footer>
                </ProdItem>
              ))}
          </ProdList>
        </div>
        <Tour
          steps={steps}
          isOpen={this.state.isTourOpen}
          onRequestClose={this.closeTour}
          showNavigationNumber={false}
          showNumber={false}
          rounded={3}
          disableInteraction={true}
          showNavigation={false}
          showCloseButton={false}
          onBeforeClose={() => localStorage.setItem("tour", 1)}
          lastStepNextButton={
            <button className="icone-e-texto">Ok, entendi!</button>
          }
        />
      </React.Fragment>
    );
  }
}

export default Album;
