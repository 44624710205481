import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5%;
  margin: 10px;
  overflow-y: auto;
  textarea {
    min-width: 100%;
    min-height: 70vh;
    border: none;
    padding: 10px;
    background-color: none;
  }
  button {
    color: #fff;
    font-size: 14px;
    background: #2f7fbc;
    margin-top: 10px;
    border: 0;
    padding: 10px;
    border-radius: 5px;
    width: 100px;
    cursor: pointer;
  }
`;
