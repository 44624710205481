import React from "react";

import { notificar } from "../../../components/Notificador";
import api from "../../../services/api";

import { Container } from "./styles";
import { parseISO, formatDistanceToNow, isBefore, addDays } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

import med_camisetas from "../../../assets/medidas_camiseta_p.png";
import medidas_camisetas from "../../../services/medidas_camisetas.json";

function Camisetas({ formatura, medida, ampliar }) {
  const { FormLimiteCamisetas, FormData: FormaturaData } = formatura;
  const lim = addDays(parseISO(FormLimiteCamisetas), 1);

  const onCamisetaChange = async (AlMedida) => {
    // eslint-disable-next-line
    if (AlMedida != 0) {
      try {
        await api.post(`camiseta`, { AlMedida });
        notificar(`Medida definida como ${AlMedida}`, "verde");
      } catch (error) {
        console.log(error);
        notificar(`A medida não pôde ser alterada.`, "vermelho");
      }
    }
  };
  return (
    <Container>
      <img
        src={med_camisetas}
        onClick={ampliar}
        title="Clique para ampliar a imagem."
      />

      <strong>Faça sua escolha aqui:</strong>
      <select
        onChange={(e) => onCamisetaChange(e.target.value)}
        disabled={isBefore(
          //controla a visibilidade do carregador de arquivos
          lim,
          new Date()
        )}
      >
        <option value="0">Selecione sua camiseta...</option>
        {medidas_camisetas.map((it) => (
          <option
            key={it.id}
            value={it.nome}
            selected={it.nome === medida ? "selected" : ""}
          >
            {it.nome}
          </option>
        ))}
      </select>
      <br />
      <p>
        Seu prazo para escolha{" "}
        {isBefore(
          //controla a visibilidade do seletor de medidas
          lim,
          new Date()
        )
          ? "encerrou"
          : "encerra"}{" "}
        <strong>
          {formatDistanceToNow(lim, {
            addSuffix: true,
            locale: ptBR,
          })}
        </strong>
        .
      </p>
    </Container>
  );
}

export default Camisetas;
