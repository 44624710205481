import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format, isBefore } from 'date-fns';
import api from "../../../services/api";


const HorariosContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const Horario = styled.div`
  margin: 5px;
  width: 100%;
  max-width: 300px;
  border: 1px solid black;
  padding: 10px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #aaa;
  }
`;

const AgendarHorarios = ({ onSubmit }) => {
  const [horarios, setHorarios] = useState([]);

  useEffect(() => {
    async function fetchHorariosOcupados() {
      const response = await api.get('/agendamentos/ocupados');
      setHorarios(response.data);
    }
    fetchHorariosOcupados();
  }, []);

  const handleClick = (horario) => {
    const [data, hora] = horario.split(' ');
    onSubmit(data, hora, '123'); // id do usuário fixo para este exemplo
  };

  const horariosDisponiveis = [
    '09:00', '09:30', '10:00', '10:30', '11:00', '11:30',
    '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30'
  ];

  const renderHorario = (horario) => {
    const dataHora = new Date(`${format(new Date(), 'yyyy-MM-dd')} ${horario}`);
    const horarioOcupado = horario == '17:00' ? true : horarios.includes(dataHora.getTime());
    return (
      <Horario
        key={horario}
        onClick={() => !horarioOcupado && handleClick(`${format(new Date(), 'yyyy-MM-dd')} ${horario}`)}
        style={{ backgroundColor: horarioOcupado ? 'gray' : 'white', color: horarioOcupado ? 'white' : 'inherit' }}
      >
        {horarioOcupado ? `${horario} está reservado` : horario}
      </Horario>
    );
  };

  return (
    <>
      <h2>Escolha um horário disponível:</h2>
      <HorariosContainer>
        {horariosDisponiveis.map(renderHorario)}
        {/* <Agendamento onSubmit={onSubmit} /> */}
      </HorariosContainer>
    </>
  );
};

/* AgendarHorarios.propTypes = {
  agendamentos: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.string.isRequired,
      horario: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
}; */

export default AgendarHorarios;

/* 
O CÓDIGO ACIMA EXPLICADO:

Este código é um componente React chamado AgendaHorarios. Ele é responsável por mostrar os horários disponíveis para agendamento e permitir que o usuário selecione um horário e envie um pedido de agendamento através da função onSubmit.

A primeira coisa que o componente faz é definir um HorariosContainer e um Horario com a ajuda do Styled Components, que é uma biblioteca para escrever CSS em JavaScript.

Em seguida, o componente define um estado horarios usando o Hook useState. O estado é inicializado como um array vazio [].

O componente usa o Hook useEffect para buscar os horários já ocupados do backend. A função fetchHorariosOcupados é uma função assíncrona que busca esses horários com uma chamada assíncrona a /horarios-ocupados. Quando a busca é concluída, o estado horarios é atualizado com os dados recebidos da API.

Depois disso, o componente define um array com todos os horários disponíveis (horariosDisponiveis) e outro array com todos os horários já ocupados (horariosAgendados). O componente então renderiza cada um dos horários disponíveis usando a função renderHorario. Essa função verifica se o horário já está ocupado e altera a cor de fundo do horário de acordo com o status do horário. Além disso, ela também adiciona um listener de evento de clique no horário para chamar a função handleClick se o horário não estiver ocupado.

A função handleClick é chamada quando o usuário clica em um horário disponível. Ela extrai a data e a hora do horário clicado e chama a função onSubmit passando essas informações e o ID do usuário que foi definido como "123" neste exemplo.

Finalmente, o componente renderiza o Agendamento que é outro componente que permite que o usuário envie um novo pedido de agendamento.


*/


/* 
Este código é uma implementação de um componente de Agenda de Horários em React utilizando styled components e a biblioteca date-fns para manipulação de datas.

A diferença principal em relação ao código anterior é a utilização do hook useEffect para gerenciar o estado dos horários disponíveis. O componente recebe como propriedades a data selecionada, um array com os horários já ocupados e uma função para ser chamada quando um novo horário for selecionado.

No useEffect, é feito um loop para criar um array com todos os horários disponíveis, baseado no horário de início e fim da agenda (9h às 12h e 13h30 às 17h30) e desconsiderando os horários já ocupados. Cada horário disponível é representado por um objeto com as propriedades "hora" (no formato HH:mm) e "estaOcupado" (um booleano que indica se o horário está ocupado ou não).

O array com os horários disponíveis é armazenado no estado do componente com o hook useState e, em seguida, renderizado na tela utilizando o componente Button, que é um styled button.

Quando o usuário seleciona um horário disponível, a função onSubmit é chamada com a data completa no formato ISO (com a data e hora selecionadas).



import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { format, addMinutes, isSameMinute, isAfter } from "date-fns";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Button = styled.button`
  background-color: ${({ isDisabled }) => (isDisabled ? "#ddd" : "#1e90ff")};
  color: #fff;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
`;

const AgendaHorarios = ({ data, horariosOcupados, onSubmit }) => {
  const [horarios, setHorarios] = useState([]);

  useEffect(() => {
    const horariosDisponiveis = [];

    const horarioInicio = new Date(`${data}T09:00:00`);
    const horarioFimManha = new Date(`${data}T12:00:00`);
    const horarioFimTarde = new Date(`${data}T17:30:00`);
    const horarioAtual = new Date(horarioInicio);

    while (isBeforeOrEqual(horarioAtual, horarioFimManha) || (isAfter(horarioAtual, horarioFimManha) && isBeforeOrEqual(horarioAtual, horarioFimTarde))) {
      const horarioOcupado = horariosOcupados.find(horario =>
        isSameMinute(new Date(horario), horarioAtual)
      );
      const horarioDisponivel = {
        hora: format(horarioAtual, "HH:mm"),
        estaOcupado: !!horarioOcupado
      };
      horariosDisponiveis.push(horarioDisponivel);
      horarioAtual.setMinutes(horarioAtual.getMinutes() + 30);
    }

    setHorarios(horariosDisponiveis);
  }, [data, horariosOcupados]);

  const handleOnClick = (hora) => {
    onSubmit(`${data}T${hora}`);
  };

  return (
    <Wrapper>
      {horarios.map(({ hora, estaOcupado }) => (
        <Button
          key={hora}
          onClick={() => handleOnClick(hora)}
          isDisabled={estaOcupado}
        >
          {hora}
        </Button>
      ))}
    </Wrapper>
  );
};

AgendaHorarios.propTypes = {
  data: PropTypes.string.isRequired,
  horariosOcupados: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default AgendaHorarios;
 */