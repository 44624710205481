import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";

import useForm from "react-hook-form";

//serviços
import api from "../../services/api";

//funções úteis
import validarCpf from "validar-cpf";
import { pontuar, capitular, databoa } from "../../services/utils";

//estilos
import "./styles/global.css";
import { Form, Container } from "./styles/styles";

export default () => {
  const { register, handleSubmit, errors } = useForm();
  const [cursos, setCursos] = useState([]);
  const [instituicao, setInstituicao] = useState([]);

  const [url] = useState(window.location.href.toString().split("/"));
  const [formatura, setFormatura] = useState(0);
  const [dia, setDia] = useState();
  const [hora, setHora] = useState();
  const [executivo, setExecutivo] = useState();
  const [store, setStore] = useState(undefined);
  let history = useHistory();

  useEffect(() => {
    async function busca() {
      console.log(url.length);
      if (url.length > 4) {
        const pos = (url.length == 5) ? 1 : 2;
        await api
          .get("/hash/" + url[url.length - pos])
          .then(async (ret) => {
            if (!ret.data.erro) {
              if (ret.data.limite === 0) {
                setCursos(ret.data.cursos);
                setFormatura(ret.data.formatura);
                setInstituicao(ret.data.instituicoes);
                setDia(databoa(ret.data.fdata));
                setHora(ret.data.fhora);
                if (url.length > 5 && url[url.length - 1] != '') {
                  setExecutivo(url[url.length - 1]);
                } else {
                  setExecutivo('e4');
                }
              } else {
                await api
                  .get(`/alunoscount/${ret.data.formatura}`)
                  .then(async (rt) => {
                    if (rt.data.count >= ret.data.limite) {
                      history.push("/full");
                    } else {
                      setCursos(ret.data.cursos);
                      setFormatura(ret.data.formatura);
                      setInstituicao(ret.data.instituicoes);
                      setDia(databoa(ret.data.fdata));
                      setHora(ret.data.fhora);
                    }
                  });
              }
              if (url.length > 5 && url[url.length - 1] != '') {
                console.log(url[url.length - 1]);
              } else {
                console.log('e4');
              }
            } else {
              history.push("/nohash");
            }
          })
          .catch((err) => {
            history.push("/nohash");
          });
      } else {
        history.push("/nohash");
      }
    }
    busca();
  }, [url, history]);

  const onSubmit = async (data) => {
    data.AlNome = capitular(`${data.AlNome.trim()} ${data.AlSobrenome.trim()}`);
    data.AlEmail = data.AlEmail.trim().toLowerCase();
    data.AlCPF = pontuar(data.AlCPF, "cpf");
    data.AlFone = pontuar(data.AlFone, "cel");
    data.AlInsta = (data.AlInsta.length) ? pontuar(data.AlInsta, "insta") : null;
    data.AlExecutivo = executivo;
    console.log(data);
    await api
      .post("/autostore", data)
      .then((str) => {
        setStore(str.data);
      })
      .catch((err) => alert(err));
  };

  return (
    <Container>
      {store !== undefined &&
        (store.status.created ? (
          <Redirect to="/success" />
        ) : (
          <Redirect to="/match" />
        ))}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <h1>Autocadastro</h1>
        <h2>{`${instituicao.InstNome} - ${dia} - ${hora}`}</h2>

        {errors.AlNome && (
          <span className="errors-autocad">{errors.AlNome.message}</span>
        )}
        <input
          type="text"
          name="AlNome"
          autoFocus
          placeholder="Digite apenas seu primeiro nome"
          ref={register({
            required: "Você precisa preencher seu primeiro nome.",
            pattern: {
              value: /[a-z]*/,
              message: "Apenas o primeiro nome.",
            },
          })}
        />
        {errors.AlSobrenome && (
          <span className="errors-autocad">{errors.AlSobrenome.message}</span>
        )}
        <input
          type="text"
          name="AlSobrenome"
          placeholder="Digite o seu sobrenome"
          ref={register({
            required: "Você precisa preencher seu sobrenome sem abreviações.",
            pattern: {
              value: /[a-z]*/,
              message: "Não abrevie o sobrenome.",
            },
          })}
        />
        {
          instituicao.InstPolos?.length && <>

            {
              errors.AlPolo && (
                <span className="errors-autocad">{errors.AlPolo.message}</span>
              )
            }
            < select
              name="AlPolo"
              ref={register({
                validate: (value) =>
                  value !== "0" || "Lembre-se de escolher seu polo.",
              })}
            >
              <option value="0">Escolha o seu polo...</option>
              {instituicao.InstPolos?.map((polo) => (
                <option value={polo.poloId} key={polo.poloId}>
                  {polo.poloNome}
                </option>
              ))}
            </select>
          </>
          || null}

        {errors.AlCurso && (
          <span className="errors-autocad">{errors.AlCurso.message}</span>
        )}
        <select
          name="AlCurso"
          ref={register({
            validate: (value) =>
              value !== "0" || "Lembre-se de escolher seu curso.",
          })}
        >
          <option value="0">Escolha seu curso...</option>
          {cursos.map((curso) => (
            <option value={curso.id} key={curso.id}>
              {curso.curso}
            </option>
          ))}
        </select>

        {errors.AlInsta && (
          <span className="errors-autocad">{errors.AlInsta.message}</span>
        )}
        <input
          type="text"
          name="AlInsta"
          placeholder="Digite o seu usuario do Instagram"
          ref={register({
            maxLength: {
              value: 30,
              message: "O usuario do Instagram não pode possuir mais de 30 caracteres.",
            },
          })}
        />

        {errors.AlEmail && (
          <span className="errors-autocad">{errors.AlEmail.message}</span>
        )}
        <input
          type="text"
          name="AlEmail"
          placeholder="Digite o seu e-mail"
          ref={register({
            required: "O preenchimento do email é obrigatório.",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Este email não parece válido.",
            },
          })}
        />

        {errors.AlCPF && (
          <span className="errors-autocad">{errors.AlCPF.message}</span>
        )}
        <input
          type="text"
          name="AlCPF"
          placeholder="Digite seu cpf (apenas números)"
          ref={register({
            required: "O preenchimento do cpf é obrigatório.",
            maxLength: {
              value: 11,
              message: "Seu CPF tem mais números do que o necessário.",
            },
            validate: (value) => validarCpf(value) || "Este CPF não é válido.",
          })}
        />

        {errors.AlFone && (
          <span className="errors-autocad">{errors.AlFone.message}</span>
        )}
        <input
          type="text"
          name="AlFone"
          placeholder="Digite seu telefone com DDD (apenas números)"
          ref={register({
            required: "O preenchimento do celular é obrigatório.",
            maxLength: {
              value: 11,
              message: "Seu fone tem mais números do que o necessário.",
            },
            pattern: {
              value: /[0-9]{11}$/,
              message: "Seu número de celular não é válido.",
            },
          })}
        />

        <input
          type="hidden"
          name="AlFormatura"
          defaultValue={formatura}
          ref={register}
        />
        {/* {(formState.isValid && 'valid') || 'invalid'} */}
        <button className="autocad" type="submit">
          Enviar
        </button>
        <hr />
        <Link
          to="/signin"
          title="Já fiz o autocadastro e já tenho usuário do portal."
          style={{
            fontWeight: 'bold',
            color: '#0167CE',
            textDecoration: 'none',
            textAlign: 'center'
          }}
        >
          Já fez o autocadastro antes?<br /> Clique aqui para acessar o portal agora.
        </Link>
      </Form>
    </Container >
  );
};
