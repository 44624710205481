import React from "react";

import "./styles/global.css";
import { Mensagem, Container } from "./styles/styles";

export default () => {
  return (
    <Container>
      <Mensagem>
        <h1>Ooops...! Todas as vagas foram preenchidas.</h1>
        <p>
          Todas vagas para esta formatura já foram preenchidas. Mas não se
          preocupe! Entre em contato conosco para fornecer-mos uma nova chave
          para você participar de outra solenidade. Qualquer dúvida entre em
          contato com a sua comissão.
        </p>
        <a className="auto" href="https://www.estudio4producoes.com.br">
          Enquanto isso, clique aqui e visite nosso site...
        </a>
      </Mensagem>
    </Container>
  );
};
