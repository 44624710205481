import React from "react";
import { Container } from "./styles";

import { POLITICA_DE_PRIVACIDADE } from "../../configs/consts";
import { useHistory } from "react-router-dom";

export default function Policy(props) {
  const { bt } = props;
  let history = useHistory();
  return (
    <Container>
      <textarea readOnly>{POLITICA_DE_PRIVACIDADE}</textarea>
      {bt !== false && (
        <button onClick={() => history.push("/signup")}>voltar</button>
      )}
    </Container>
  );
}
