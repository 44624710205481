import React from "react";

import "./styles/global.css";
import { Mensagem, Container } from "./styles/styles";

export default () => {
  return (
    <Container>
      <Mensagem>
        <h1>Tudo certo! Você já está cadastrado no sistema.</h1>
        <p>Qualquer dúvida entre em contato conosco.</p>
        <a className="auto" href={`https://e4producoes.com.br/signup`}>
          Agora vamos criar seu usuário em nosso portal. Clique aqui.
        </a>
      </Mensagem>
    </Container>
  );
};
