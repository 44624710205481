import React, { useState } from "react";
import GeraPagamento from "../../../components/GeraPagamento";

import { notificar } from "../../../components/Notificador";
import api from "../../../services/api";

import { Container } from "./styles";
import { parseISO, format, subDays, addHours, isBefore, isAfter } from "date-fns";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandHoldingUsd,
  faQrcode,
  faCreditCard
} from "@fortawesome/free-solid-svg-icons";
import Endereco from "../../../components/Endereco";

function Acessos({ cadastro, contrato }) {
  const [acc, setAcc] = useState(1);
  const [pagando, setPagando] = useState(false);
  const [idItem, setIdItem] = useState(null);
  const [showPagamento, setShowPagamento] = useState(false);
  const {
    FormTotalAcessos,
    FormTotalAcessosReservados,
    FormTotalAcessosExtras,
    FormValorAcessoExtra,
    FormData: FormaturaData,
  } = cadastro.AlFormDados;
  const lim = subDays(parseISO(FormaturaData), 5);
  const temEndereco = Boolean(cadastro.AlRevEndereco);

  const onQtChange = (qt) => {
    setAcc(qt > FormTotalAcessosExtras ? FormTotalAcessosExtras : qt);
  };

  const onPagar = () => {
    notificar('Pagamento indisponível temporariamente.');
  }
  /*const onPagar = async () => {
    setPagando(true);
    setAcc((prev) =>
      prev > FormTotalAcessosExtras ? FormTotalAcessosExtras : prev
    );
    if (acc <= FormTotalAcessosExtras) {
      try {
        const reserva = await api.post(`reservarItens`, {
          pctIdItem: 73, //acesso a formatura
          pctItemNome: "ACESSO EXTRA DE CONVIDADOS NA FORMATURA",
          pctItemValor: FormValorAcessoExtra,
          pctItemQuant: acc,
        });
        if (reserva.data.error) {
          notificar(reserva.data.msg, "vermelho");
        } else {
          notificar(
            `Seus acessos foram reservados. Efetue o pagamento agora.`,
            "verde"
          );
          setIdItem(reserva.data.pctItemId);
          setShowPagamento(!showPagamento);
        }
      } catch (error) {
        console.log(error);
        notificar(`Não pudemos reservar seus acessos. Desculpe.`, "vermelho");
      }
    }
    setPagando(true);
  };*/

  return (
    <Container>
      {(isAfter(
        //controla a visibilidade do fornecedor de acessos
        lim,
        new Date()
      ) && (
          <>
            <p>
              Seu pacote contratado lhe dá direito a {FormTotalAcessosReservados}{" "}
              acesso(s) para convidados.
            </p>
            {FormTotalAcessosExtras > 0 &&
              <>
                <label>
                  Quantos <strong>ACESSOS EXTRAS</strong> você precisa?{" "}
                  <input
                    type="number"
                    defaultValue={acc}
                    onChange={(e) => onQtChange(e.target.value)}
                    min="1"
                    step="1"
                    max={FormTotalAcessosExtras}
                    disabled={pagando}
                  />
                </label>
                <p>
                  Ainda restarão {FormTotalAcessosExtras - acc} acesso(s) disponíveis
                  para venda. Aproveite.
                </p>
                <Endereco view={!temEndereco} notificacao="Você precisa preencher seus dados de endereço para efetuar a compra de acessos extras." />
                {showPagamento && temEndereco && (
                  <GeraPagamento
                    contrato={contrato}
                    valor={acc * FormValorAcessoExtra}
                    tipo="Extras"
                    categoria="Acessos"
                    forma={2}
                    venda={idItem}
                    icone={faCreditCard}
                  />
                )}
                {!showPagamento && temEndereco && (
                  <button
                    className="icone-e-texto"
                    title="Clique para gerar um pagamento."
                    onClick={() => onPagar()}
                  >
                    <FontAwesomeIcon icon={faHandHoldingUsd} /> Efetuar pagamento
                  </button>
                )}
                <p>
                  <strong>
                    Observações importantes sobre a compra de ACESSOS EXTRAS:
                  </strong>
                  <br />- enquanto seu pagamento não for aprovado seus acessos extras
                  NÃO estarão efetivados;
                  <br />- para pagamento aceitamos apenas cartões de débito ou crédito à
                  vista (1x);
                  <br />- NÃO fazemos reservas para pagamento posterior.
                </p>
              </> || null}
          </>
        )) || <p>Sem mais acessos.</p>}
    </Container>
  );
}

export default Acessos;
