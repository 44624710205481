import React, { useContext, useState, useEffect } from "react";
import * as DOC from "../../configs/consts";
import { StateContext } from "../../context/Context";
import { format, addBusinessDays } from "date-fns";
import api from "../../services/api";

import FormBoleto from "../FormBoleto";
import { Container } from "./styles";
import { notificar } from "../Notificador";
import { refreshFrete, criaPix } from "../../services/utils";
import { useHistory } from "react-router-dom";
import { faCreditCard, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GeraPix from "../GeraPix";
import { CHAVE_PIX, TITULAR_PIX, CIDADE_PIX } from "../../configs/consts";

export default function GeraPagamento(props) {
  const history = useHistory();
  const context = useContext(StateContext);
  const {
    categoria, //controla o tipo de extra a ser cobrado
    //ao preencher estes, faz uma via de boleto de uma parcela qualquer...
    montar = null,
    via = 1,
    icone = faEye,
    texto = null,
    forma,
    //...estes são para criar uma parcela nova
    contrato,
    valor,
    tipo,
    prazo = 5,
    venda = null,
    desconto = 0,
    comPedido = false, //se true, indica um pedido associado. Preencher venda com o id do pedido
    className = "icone", //formatação de visual para o botão
  } = props;
  const [showPix, setShowPix] = useState(false);
  const [showBoleto, setShowBoleto] = useState(false);
  const [showCartao, setShowCartao] = useState(false);
  const [lnkCartao, setLnkCartao] = useState("");
  const [boleto, setBoleto] = useState([]);
  const [dadosPix, setDadosPix] = useState([]);
  const vencimento = addBusinessDays(new Date(), prazo);
  const showRevEndereco = Boolean(!context.state.cadastro.AlRevEndereco);

  const createParcela = () => {
    if (via === 1) {
      //cria requisição de pagamento
      let dataParcela = {
        parcconta: contrato,
        parcvalor: valor,
        parcdesconto: desconto,
        parctipo: tipo,
        parcforma: forma,
        parcvencimento: format(vencimento, "yyyy-MM-dd"),
        parctransacao: null,
        parcadmin: "Portal",
        parcorigem: "Portal",
      };
      //caso boleto insere taxas
      if (forma === 5) {
        dataParcela.parctaxa = DOC.TAXA_BOLETO;
        dataParcela.parctitulo = 1;
      }

      //verifica se vem de um outro tipo de venda
      if (venda !== null) {
        dataParcela.parcvenda = venda;
      }
      //verifica se é pedido e cria um id
      if (comPedido) {
        const current_timestamp = format(new Date(), "yyyy-MM-dd HH:mm:ss");
        api.get(`pedidos/novo`).then((dados) => {
          dataParcela.parcvenda = dados.data.pedId;

          if (dataParcela.parcvalor - dataParcela.parcdesconto === 0) {
            dataParcela.parcpagoem = current_timestamp;
            dataParcela.parcdoc = "Pacote contratado";
          }
          //cria parcela
          api.post(`pagamentos/novaparcela`, dataParcela).then((dados) => {
            if (!dados.data.error) {
              // cria requisição
              const { cart, checkout } = context.state;
              //dados para envio
              let dataPedido = {
                order: { cart, checkout, financeiro: dados.data },
                pedido: {
                  pedFrete:
                    checkout.frete.length > 0 &&
                      checkout.frete[0].hasOwnProperty("valor")
                      ? checkout.frete[0].valor
                      : 0,
                  pedModalidadeFrete:
                    checkout.frete.length > 0 ? checkout.frete[0].nome : null,
                  pedQuantidade: cart.reduce((a, b) => a + b.qt, 0),
                  pedImpressa: refreshFrete(cart),
                  pedSoDigital: !refreshFrete(cart),
                  /* pedObs: checkout.obs, TODO */
                  pedDesconto: checkout.descontos.total,
                  pedTotal: checkout.bruto,
                },
              };
              //consulta descontos e isenções
              if (
                dataPedido.pedido.pedTotal - dataPedido.pedido.pedDesconto ===
                0
              ) {
                dataPedido.pedido.pedFinanceiro = current_timestamp;
                dataPedido.pedido.pedDoc = "Pacote contratado";
                dataPedido.pedido.pedAdmFinanceiro = "Portal";
                dataPedido.pedido.pedAdmin = "Portal";

                //pula a linha de produção caso desnecessária
                if (!refreshFrete(cart)) {
                  dataPedido.pedido.pedProcessamento = current_timestamp;
                  dataPedido.pedido.pedOk = current_timestamp;
                  dataPedido.pedido.pedEntregue = current_timestamp;
                  dataPedido.pedido.pedAdmEntrega = "Portal";
                }
              }

              api.post(`pedidos/preencher`, dataPedido).then((dados) => {
                dataPedido.parcvenda = dados.pedId;
                //notificar por email aqui
                if (
                  dataPedido.pedido.pedOk &&
                  dataPedido.pedido.pedOk !== null
                ) {
                  api.post(`notificar/pedido/${dataParcela.parcvenda}`);
                }
                notificar(`Pedido efetuado com sucesso.`, "verde");
                context.actions.setCheckout({
                  frete: {},
                  descontos: [],
                  bruto: 0,
                  liquido: 0,
                });
                /* history.push(
                  `/resumo/E-commerce/${dataPedido.order.financeiro.parcid}-${dataPedido.order.financeiro.parcvenda}-${dataPedido.order.financeiro.parcconta}`
                ); */ //terceiro dado é conta e não usuário
              });

              if (forma === 5) {
                setBoleto(montaBoleto(dados.data));
                setShowBoleto(true);
                notificar(`Clique em 'EXIBIR BOLETO' para emitir agora.`);
              } else if (forma === 2) {
                api
                  .post(`pagamentos/linkCheckout`, dataPedido)
                  .then((dados) => {
                    setLnkCartao(dados.data.checkoutUrl);
                    setShowCartao(true);
                    notificar(
                      `Clique em 'PAGAR AGORA' para ser redirecionado para a Cielo.`
                    );
                  });
              } else if (forma === 6) {
                const code = criaPix({
                  chave: CHAVE_PIX,
                  mensagem: `${tipo} ${tipo === "E-commerce"
                    ? dataPedido.order.financeiro.parcvenda
                    : dataPedido.order.financeiro.parcconta
                    }`,
                  titular: TITULAR_PIX,
                  cidade: CIDADE_PIX,
                  transacao: `${dataPedido.order.financeiro.parcid}`,
                  valor: dataPedido.order.financeiro.parcvalor,
                });

                api
                  .post(
                    `pagamentos/pix/${dataPedido.order.financeiro.parcid}`,
                    { code: code }
                  )
                  .then((dados) => {
                    setDadosPix(dataPedido.order.financeiro);
                    setShowPix(true);
                  });
                //notificar(`Clique em 'EXIBIR BOLETO' para emitir agora.`);
              } else {
                history.push("/");
              }
            }
          });
        });
      } else {
        api.post(`pagamentos/novaparcela`, dataParcela).then((dados) => {
          if (!dados.data.error) {
            if (forma === 5) {
              setBoleto(montaBoleto(dados.data));
              setShowBoleto(true);
              notificar(`Clique em 'EXIBIR BOLETO' para emitir agora.`);
            } else if (forma === 2) {
              api
                .post(`pagamentos/linkCheckout`, {
                  order: {
                    financeiro: dados.data,
                    parcelamento: { selecionado: 1 },
                  },
                })
                .then((dt) => {
                  setLnkCartao(dt.data.checkoutUrl);
                  setShowCartao(true);
                });
            } else if (forma === 6) {
              const code = criaPix({
                chave: CHAVE_PIX,
                mensagem: `${tipo} ${tipo === "E-commerce" || tipo === "Extras"
                  ? dados.data.parcvenda
                  : dados.data.parcconta
                  }`,
                titular: TITULAR_PIX,
                cidade: CIDADE_PIX,
                transacao: `${dados.data.parcid}`,
                valor: dados.data.parcvalor,
              });

              api
                .post(
                  `pagamentos/pix/${dados.data.parcid}`,
                  { code: code }
                )
                .then((ddt) => {
                  setDadosPix(dados.data);
                  setShowPix(true);
                });
            } else {
              notificar(`Pedido efetuado com sucesso.`, "verde");
              history.push("/");
              return dados.data;
            }
          } else {
            notificar(
              `A operação financeira não pode ser processada.`,
              "vermelho"
            );
            console.log(dados.data.error);
          }
        });
      }
    } else {
      if (montar !== null) {
        setBoleto(montaBoleto(montar));
        setShowBoleto(true);
      }
    }
  };

  useEffect(() => {
    createParcela();
    /* return function cleanup() {
      clearCart("gera");
    }; */
  }, []);

  const montaBoleto = (dados) => {
    const p = dados;
    const nosso = String(p.parcid);
    const dvenc = p.parcvencimento.split("-");
    const vl = p.parcvalor + parseFloat(DOC.TAXA_BOLETO);
    const val = String(vl.toFixed(2));
    const idParcela = p.parcvenda !== null ? p.parcvenda : p.parcid;

    return {
      idConv: DOC.ID_CONV,
      refTran: `${DOC.REF_TRAN}${nosso.padStart(10, "0")}`,
      valor: val.replace(".", ""),
      dtVenc: `${dvenc[2]}${dvenc[1]}${dvenc[0]}`,
      tpPagamento: via === 1 ? DOC.TP_PAGAMENTO : DOC.TP_VIA_2,
      indicadorPessoa: DOC.INDICADOR_PESSOA,
      tpDuplicata: DOC.TP_DUPLICATA,
      urlRetorno: DOC.URL_RETORNO,
      cpfCnpj: context.state.cadastro.AlCPF
        ? context.state.cadastro.AlCPF.replace(/[^\d]+/g, "")
        : null,
      nome: context.state.cadastro.AlNome,
      endereco: `${context.state.cadastro.AlEndereco}, ${context.state.cadastro.AlNumero} ${context.state.cadastro.AlComp}`,
      cidade: context.state.cadastro.AlCidade,
      uf: context.state.cadastro.AlUF,
      cep: context.state.cadastro.AlCEP,
      msgLoja: `Pagamento ref. ${p.parctipo} ${montar === null ? contrato : p.parcconta
        }-${idParcela}`,
    };
  };

  return (
    <Container>
      {forma === 6 && showPix && (
        <GeraPix dados={dadosPix} onClick={(e) => e.stopPropagation()} />
      )}
      {forma === 5 && (
        <FormBoleto
          dados={boleto}
          showBoleto={showBoleto}
          via={via}
          icone={icone}
          texto={texto}
          className={className}
          showRevEndereco={showRevEndereco}
          onClick={(e) => e.stopPropagation()}
        />
      )}
      {forma === 2 && showCartao && (
        <a
          href={lnkCartao}
          style={{
            padding: "5px",
            margin: "5px",
            backgroundColor: "#ccc",
            textDecoration: "none",
          }}
          rel="noopener noreferrer"
          className="icone-e-texto"
          onClick={(e) => e.stopPropagation()}
        >
          <FontAwesomeIcon icon={faCreditCard} />
          PAGAR AGORA
        </a>
      )}
    </Container>
  );
}
