import styled from "styled-components";
/* padrão sp: #0A4786, #002d5a, #021b33*/

export const Corpo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100vh;
`;

export const Head = styled.header`
  width: 95vw;
  height: 20vh;
  display: flex;
  flex-direction: row;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 870px) {
    img {
      width: 60%;
    }
  }
  .perfil {
    display: flex;
    flex-direction: row;
    p {
      text-align: right;
    }
    @media only screen and (max-width: 768px) {
      p > span#head-details {
        display: none;
      }
    }

    button,
    a {
      margin-left: 15px;
      padding: 5px;
      font-size: 15px;
    }
  }
`;

export const DashboardList = styled.ul`
  @media only screen and (max-width: 768px) {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    list-style: none;
  }

  @media only screen and (min-width: 769px) {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    //grid-template-rows: masonry;
    grid-gap: 10px;
    list-style: none;
  }
`;

export const ToolsItem = styled.p`
  .icon-tools {
    margin-right: 10px;
  }

  :not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
  padding: 5px;

  :hover {
    background-color: #eee;
    cursor: pointer;
  }
`;

export const FilesItem = styled.p`
  .icon-files {
    margin-right: 10px;
  }
  .ok {
    color: #00bf00;
  }

  .danger {
    color: #bf0000;
  }

  .pending {
    color: #666;
  }

  .icon-files-separator {
    margin: 0 20px;
  }

  :not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
  padding: 5px;

  :hover {
    background-color: #eee;
    cursor: pointer;
  }
`;

export const Container = styled.div`
  width: 95vw;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  .le {
    width: calc(30% - 10px);
    height: 100%;
  }
  .ld {
    border-radius: 8px;
    height: 100%;
    background-color: #fff;
    width: 70%;
  }
`;

export const Form = styled.form`
  width: 90vw;
  height: 95vh;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 100px;
    margin: 10px 0 40px;
  }
  p {
    color: #ff3333;
    margin-bottom: 15px;
    border: 1px solid #ff3333;
    padding: 10px;
    width: 100%;
    text-align: center;
  }
  input {
    flex: 1;
    height: 46px;
    margin-bottom: 15px;
    padding: 15px;
    color: #777;
    font-size: 15px;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    &::placeholder {
      color: #999;
    }
  }
  button {
    color: #fff;
    font-size: 16px;
    background: #7159c1;
    height: 56px;
    border: 0;
    border-radius: 5px;
    width: 100%;
  }
  hr {
    margin: 20px 0;
    border: none;
    border-bottom: 1px solid #cdcdcd;
    width: 100%;
  }
  a {
    font-size: 16;
    font-weight: bold;
    color: #999;
    text-decoration: none;
  }
`;

export const MsgItem = styled.div`
  border-radius: 5px;
  background-color: #b7d6ff;
  width: 95%;
  margin: 10px;
  padding: 10px;
  color: #006dff;
  .msg-rodape {
    border-top: 1px solid #006dff;
    padding: 3px;
    margin-top: 10px;
    margin-bottom: -10px;
    text-align: right;
    color: #006dff;
    font-size: 0.7em;
  }
`;

export const PrtItem = styled.a`
  max-width: 99%;
  width: 100%;
  margin: 5px;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  text-decoration: none;
`;

export const EvtItem = styled.div`
  max-width: 300px;
  width: 100%;
  margin: 5px;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  p {
    text-align: right;
    color: #666;
  }
  .evt-rodape {
    font-size: 0.7em;
    margin-top: -8px;
  }
  .evt-titulo {
    font-weight: 600;
  }
  img {
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  :hover {
    background-color: #eee;
  }
`;
