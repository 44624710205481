import styled from "styled-components";

export const Recado = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #afe0ff;
  border: 1px solid #006dff;
  p > img {
    margin-bottom: -9px;
  }
`;

export const RecadoAdmin = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  margin: 0 10px 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border-radius: 0 0 3px 3px;
  font-size: 0.8em;
  background-color: ${props => props.convidado ? "#00bf00" : "#ff000088"};
  color: #fff;
  cursor: default;
`;
