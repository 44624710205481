import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    max-width: 95%;
    cursor: pointer;
  }
  select {
    padding: 5px;
    margin: 5px;
  }
`;
