import styled from "styled-components";

export const Container = styled.div`
  background-color: #ddd;
  padding: 10px;
  

  h3 {
    border-bottom: 1px solid #fff;
  }
  p {
    padding-top: 10px;
  }
`;
