import React, { Component } from "react";
import { uniqueId } from "lodash";
import filesize from "filesize";

import api from "../../services/api";
import { StateContext } from "./../../context/Context";

import { Corpo, Container } from "./styles";
import { Recado } from "../../components/styled-components-global";

import Upload from "./Upload";
import FileList from "./FileList";
import tipos_upload_individual from "../../services/tipos_upload_individual.json";
import tipos_upload_formatura from "../../services/tipos_upload_formatura.json";
import tipos_upload_complementar from "../../services/tipos_upload_complementar.json";
import { notificar } from "../../components/Notificador";
import { isAfter, parseISO, sub, format, formatDistanceToNow } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

export default class Uploads extends Component {
  static contextType = StateContext; // chave de acesso ao contexto

  state = {
    uploadedFiles: [],
    tipos: [],
    upMaximo: 0,
    alterarUploads: false,
  };

  async componentDidMount() {
    const { tipo } = this.props;

    const response = await api.get(`uploads/${tipo}`);
    //controla os repositórios para nomear os arquivos
    const {
      FormFtInfancia,
      FormFtHomenagem,
      FormFtLed,
      FormLimiteEntrega,
      FormLimiteComplementares,
      FormData: FormaturaData,
    } = this.context.state.cadastro.AlFormDados;

    const tpControle = [FormFtInfancia, FormFtHomenagem, FormFtLed];

    let tpUpload = [];
    if (tipo === "individual") {
      tipos_upload_individual.map((tp, i) => {
        if (tpControle[i] > 0) {
          tp.qt = tpControle[i];
          tpUpload.push(tp);
        }
        return false;
      });
    } else if (tipo === "formatura") {
      tpUpload = tipos_upload_formatura;
    } else if (tipo === "complementar") {
      tpUpload = tipos_upload_complementar;
    }
    //seta estado geral
    this.setState({
      uploadedFiles: response.data.map((file) => ({
        id: file._id,
        name: file.name,
        readableSize: filesize(file.size),
        preview: file.url,
        mimetype: file.mimetype,
        uploaded: true,
        url: file.url,
        label: file.label,
        obs: file.obs,
      })),
      lmtUp: formatDistanceToNow(
        tipo === "complementar"
          ? parseISO(FormLimiteComplementares)
          : sub(parseISO(FormaturaData), { days: FormLimiteEntrega - 1 }),
        {
          addSuffix: true,
          locale: ptBR,
        }
      ),
      tipos: tpUpload,
      tipo,
      upMaximo:
        tipo === "complementar"
          ? 3 //hack para 2
          : tpControle.reduce((total, numero) => total + numero, 0) + 1, //+1 é hack também
      alterarUploads: isAfter(
        //controla a visibilidade do carregador de arquivos
        tipo === "complementar"
          ? parseISO(FormLimiteComplementares)
          : sub(parseISO(FormaturaData), { days: FormLimiteEntrega - 1 }),
        new Date()
      ),
      temMusica: Boolean(
        response.data.filter(
          (it) =>
            it.hasOwnProperty("mimetype") && it.mimetype.indexOf("audio") !== -1
        ).length > 0
      ),
    });
  }

  handleUpload = (files) => {
    const carregouMusica = files.filter(
      (it) => it.type.indexOf("audio") !== -1
    );

    if (carregouMusica.length > 0 && this.state.tipo !== "formatura") {
      if (this.state.temMusica) {
        notificar("Você não pode carregar mais de uma música.", "vermelho");
        return null;
      }
    }

    const quantasImagens = this.state.uploadedFiles.filter(
      (it) =>
        it.hasOwnProperty("mimetype") && it.mimetype.indexOf("image") !== -1
    );

    if (
      carregouMusica.length === 0 &&
      this.state.upMaximo - 1 === quantasImagens.length
    ) {
      notificar("Você não pode carregar mais imagens.", "vermelho");
      return null;
    }

    const uploadedFiles = files.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      mimetype: file.mimetype,
      tipo: this.state.tipo,
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles),
      temMusica: carregouMusica.length > 0 ? true : this.state.temMusica,
    });

    uploadedFiles.forEach(this.processUpload);
  };

  updateFile = (id, data) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map((uploadedFile) => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      }),
    });
  };

  processUpload = (uploadedFile) => {
    const data = new FormData();

    data.append("file", uploadedFile.file, uploadedFile.name);
    data.append("tipo", this.state.tipo);

    notificar(
      "O arquivo está sendo carregado. Aguarde a conclusão.",
      "amarelo"
    );
    api
      .post("uploads", data, {
        onUploadProgress: (e) => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));
          this.updateFile(uploadedFile.id, {
            progress,
          });
        },
      })
      .then((response) => {
        this.updateFile(uploadedFile.id, {
          uploaded: true,
          id: response.data._id,
          url: response.data.url,
          mimetype: response.data.mimetype,
          //tipo: response.data.tipo,
        });
        if (response.data.tipo === "complementar") {
          api
            .post("/complementar", {
              tipo: response.data.tipo,
              content: { caminho: response.data.url, nome: response.data.name },
              relativo: response.data._id,
            })
            .then(() => {
              notificar(
                "Foto complementar carregada. Escolha para qual produto você quer usá-la.",
                "verde"
              );
            });
        } else {
          notificar("O arquivo foi carregado corretamente.", "verde");
        }
      })
      .catch(() => {
        notificar("Não foi possível carregar o arquivo.", "vermelho");
        this.updateFile(uploadedFile.id, {
          error: true,
        });
      });
  };

  handleDelete = async (id, mimetype) => {
    if (this.state.alterarUploads) {
      await api.delete(`uploads/${id}`);
      notificar("Arquivo excluído com sucesso.", "verde");
      this.setState({
        uploadedFiles: this.state.uploadedFiles.filter(
          (file) => file.id !== id
        ),
        temMusica:
          mimetype !== undefined && mimetype.indexOf("audio") !== -1
            ? false
            : this.state.temMusica,
      });
    } else {
      notificar("Não é mais possível excluir este arquivo.", "amarelo");
    }
  };

  handleLabelChange = async (label, id, tipo, index) => {
    // eslint-disable-next-line
    if (label != 0) {
      await api.post(`uploads/label`, { id, label });
      notificar(`Arquivo definido como ${label}`, "verde");
    }
    if (tipo === "complementar") {
      const tp = index === 1 ? "quadro" : "convite";
      const ret = await api.post(`complementar/tipo`, { id, tp });
      //console.log(ret);
    }
  };

  handleObsChange = async (obs, id) => {
    // eslint-disable-next-line
    if (obs !== "") {
      await api.post(`uploads/obs`, { id, obs });
      notificar(`Observação anotada.`, "verde");
    }
  };

  handleTimeChange = async (data, id) => {
    const tempo = ` - desde ${
      // eslint-disable-next-line
      data == 0
        ? `o início`
        : `${new Date(data * 1000)
            .toISOString()
            .substr(14, 5)
            .replace(":", "m")}s.`
    }`;
    const label = `Música de entrada${tempo}`;
    await api.post(`uploads/time`, { id, label, time: data });
    notificar(label, "verde");
  };

  componentWillUnmount() {
    this.state.uploadedFiles.forEach((file) =>
      URL.revokeObjectURL(file.preview)
    );
  }

  render() {
    const { uploadedFiles, tipos, upMaximo, tipo } = this.state;
    return (
      <Corpo>
        <Container>
          {uploadedFiles.length < upMaximo && this.state.alterarUploads ? (
            <>
              {tipo === "individual" && (
                <p style={{ fontSize: "0.8em", textAlign: "center" }}>
                  Você pode carregar {upMaximo - 1} imagens e 1 música de
                  entrada. <strong>O prazo encerra {this.state.lmtUp}</strong>
                </p>
              )}
              {tipo === "formatura" && (
                <p style={{ fontSize: "0.8em", textAlign: "center" }}>
                  Você pode carregar músicas de entrada e encerramento e outros
                  momentos para sua formatura aqui.{" "}
                  <strong>O prazo encerra {this.state.lmtUp}</strong>
                </p>
              )}
              {tipo === "complementar" && (
                <p style={{ fontSize: "0.8em", textAlign: "center" }}>
                  Você pode carregar 2 imagens.{" "}
                  <strong>O prazo encerra {this.state.lmtUp}</strong>
                </p>
              )}
              <Upload onUpload={this.handleUpload} tipo={tipo} />
            </>
          ) : this.state.alterarUploads ? (
            <Recado>Você já carregou todos os arquivos necessários.</Recado>
          ) : (
            <Recado>
              Seu prazo para alteração dos conteúdos expirou {this.state.lmtUp}.
            </Recado>
          )}
          {!!uploadedFiles.length && (
            <FileList
              files={uploadedFiles}
              onDelete={this.handleDelete}
              onLabelChange={this.handleLabelChange}
              onTimeChange={this.handleTimeChange}
              onObsChange={this.handleObsChange}
              tipos={tipos}
              tipo={tipo}
            />
          )}
        </Container>
      </Corpo>
    );
  }
}
